import * as React from "react";
import useAxios from "axios-hooks";
import config from "../config/config.js";
import { useDispatch, useSelector } from "react-redux";
import types from "./../reducers/types.js";
import Cookies from "js-cookie";

const useTargetsActions = () => {
  const [internalState, setInternalState] = React.useState(null);
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state?.auth?.user);
  const kidsRedux = useSelector((state) => state?.kids?.kids);
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: `${config.urlServer}/targets`,
      method: "POST",
    },
    { manual: true, useCache: true }
  );

  const doRequest = async () => {
    const response = await executeRequest({
      data: {
        ParentId: userRedux?.Id,
        Cookie: cookie,
      },
    });
    if (response?.data?.Targets?.length > 0) {
      dispatch({
        type: types.FETCH_TARGETS_SUCCESS,
        payload: { Targets: response?.data?.Targets, Kids: kidsRedux },
      });
    }
    // if you want or need the response you can enable this, for this use case we just want to fill redux state with server data.
    //setInternalState(response.data);
  };

  return [internalState, doRequest];
};

export default useTargetsActions;
