const types = {
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER: "LOGIN_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  CREATE_BANK_ACCOUNT_SUCCESS: "CREATE_BANK_ACCOUNT_SUCCESS",
  REGISTER_USER: "REGISTER_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_BANK_ACCOUNT_SUCCESS: "UPDATE_BANK_ACCOUNT_SUCCESS",
  SELECT_BANK: "SELECT_BANK",
  LOADING_AUTH: "LOADING_AUTH",
  FORGOTPASSWORD_USER: "FORGOTPASSWORD_USER",
  FORGOTPASSWORD_USER_SUCCESS: "FORGOTPASSWORD_USER_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  RESET_AUTH: "RESET_AUTH",
  REGISTER_KIDS: "REGISTER_KIDS",
  REGISTER_KID_SUCCESS: "REGISTER_KID_SUCCESS",
  FETCH_KIDS_SUCCESS: "FETCH_KIDS_SUCCESS",
  FETCH_KIDS_PIGGIES_SUCCESS: "FETCH_KIDS_PIGGIES_SUCCESS",
  FETCH_KIDS_PENDING_WITHDRAWALS_SUCCESS:
    "FETCH_KIDS_PENDING_WITHDRAWALS_SUCCESS",
  SELECTED_KID: "SELECTED_KID",
  EDIT_KIDS_SUCCESS: "EDIT_KIDS_SUCCESS",
  PAIR_KIDS_SUCCESS: "PAIR_KIDS_SUCCESS",
  CLEAN_CHILDS_IDS_SUCCESS: "CLEAN_CHILDS_IDS_SUCCESS",
  WITHDRAW_KIDS_SUCCESS: "WITHDRAW_KIDS_SUCCESS",
  STATUS_KIDS_SUCCESS: "STATUS_KIDS_SUCCESS",
  USER_KID_DEVICEID_SUCCESS: "USER_KID_DEVICEID_SUCCESS",
  FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  CLEAN_TASKS_IDS_SUCCESS: "CLEAN_TASKS_IDS_SUCCESS",
  LOADING_TASKS: "LOADING_TASKS",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  SELECTED_TASK_SUCCESS: "SELECTED_TASK_SUCCESS",
  UPDATE_MODAL: "UPDATE_MODAL",
  LOADING_GIFTS: "LOADING_GIFTS",
  ADD_GIFTS: "ADD_GIFTS",
  FETCH_BANKS_SUCCESS: "FETCH_BANKS_SUCCESS",
  FETCH_BANK_ACCOUNT_SUCCESS: "FETCH_BANK_ACCOUNT_SUCCESS",
  FETCH_GIFTS_SUCCESS: "FETCH_GIFTS_SUCCESS",
  CLEAN_GIFTS_IDS_SUCCESS: "CLEAN_GIFTS_IDS_SUCCESS",
  THIRD_SELECTED_GIFTS_SUCCESS: "THIRD_SELECTED_GIFTS_SUCCESS",
  THIRD_GIFTS_LINK: "THIRD_GIFTS_LINK",
  THIRD_GIFTS_URLS: "THIRD_GIFTS_URLS",
  THIRD_GIFTS_USER: "THIRD_GIFTS_USER",
  FETCH_TARGETS_SUCCESS: "FETCH_TARGETS_SUCCESS",
  FETCH_TARGETS_CATEGORIES_SUCCESS: "FETCH_TARGETS_CATEGORIES_SUCCESS",
  SELECTED_TARGET_SUCCESS: "SELECTED_TARGET_SUCCESS",
  FETCH_PIGGY_TRANSACTIONS_SUCCESS: "FETCH_PIGGY_TRANSACTIONS_SUCCESS",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
  UNSUBSCRIBE_KIDS_SUCCESS: "UNSUBSCRIBE_KIDS_SUCCESS",
  TIP_KIDS: "TIP_KIDS",
  TIP_TASKS: "TIP_TASKS",
  TIP_TARGETS: "TIP_TARGETS",
  TIP_GIFTS: "TIP_GIFTS",
  TIP_BANKS: "TIP_BANKS",
  TIP_RESET: "TIP_RESET",
  NEWS_SHOW: "NEWS_SHOW",
  NEWS_INFO: "NEWS_INFO",
  REMOVE_PROFILE_PHOTO: 'REMOVE_PROFILE_PHOTO',
  REMOVE_KID_PHOTO: 'REMOVE_KID_PHOTO'
};

export default types;
