import React from "react";
import useAxios from "axios-hooks";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import useKidsActions from "./../../../Actions/KidsActions.js";

import config from "../../../config/config";
import HeaderModal from "./../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./disable.module.scss";

export default function Disable({ navigate }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/kids/kid-status",
      method: "POST",
    },
    { manual: true }
  );
  const placeholders = {
    motivo: t("forms.motive"),
  };
  const dispatch = useDispatch();
  const cookie = Cookies.get("user");
  const kidRedux = useSelector((state) => state.kids.selectedKid);

  const [response, kidsRequest] = useKidsActions();

  React.useEffect(() => {
    if (response && response.OperationStatus === "Ok") {
      navigate("/dashboard");
    }
  }, [response]);

  const callKidsAction = async () => {
    const responseCallAction = await kidsRequest();
  };

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${t("errors.disableUser")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);
  const onSubmit = async () => {
    const response = await executeRequest({
      data: {
        Id: kidRedux?.Child?.Id,
        Status: "false",
        Cookie: cookie,
      },
    });
    dispatch({
      type: types.STATUS_KIDS_SUCCESS,
      payload: response?.data,
    });
    toast.success(`${t("success.desactivatingUser")}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    callKidsAction();
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t("kidDisable.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="red"
        />
        <form className={styles.mainContent} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <p>
              {t("kidDisable.areYouSure")} {kidRedux?.Child?.FirstName}{" "}
              {kidRedux?.Child?.LastName}?
            </p>
          </div>
          <div className={styles.row}>
            <img
              className={styles.pigPhoto}
              src="/cryPig.png"
              alt={`${kidRedux?.FirstName} ${kidRedux?.LastName}`}
            />
          </div>
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t("kidDisable.disable")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
