import React from "react";
import useAxios from "axios-hooks";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import config from "../../../config/config";
import HeaderModal from "./../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./delete-task.module.scss";

export default function DeleteTask({ navigate }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/tasks/delete-tasks",
      method: "POST",
    },
    { manual: true }
  );

  const dispatch = useDispatch();
  const cookie = Cookies.get("user");
  const selectedTaskRedux = useSelector((state) => state.tasks.selectedTask);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error?.response?.data?.Error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);
  const onSubmit = async () => {
    const { task } = selectedTaskRedux;
    const myTask = {
      Id: task?.Id,
      Description: task?.Description,
      Amount: task.Amount,
      Schedule: {
        Type: "once",
        Data: "",
      },
      Status: false,
      End: "",
      ChildId: task?.ChildId,
    };
    const response = await executeRequest({
      data: {
        Tasks: { ...myTask },
        Cookie: cookie,
      },
    });

    dispatch({
      type: types.DELETE_TASK_SUCCESS,
      payload: task?.TaskId,
    });

    navigate("../");
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t("deleteTasks.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="red"
        />
        <form className={styles.mainContent} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <p>
              {t("deleteTasks.wannaDeleteTask")}
              {selectedTaskRedux?.Description}?
            </p>
          </div>
          <div className={styles.row}>
            <img
              className={styles.pigPhoto}
              src="/cryPig.png"
              alt={`${selectedTaskRedux?.Description}`}
            />
          </div>
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t("deleteTasks.deleteTask")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
