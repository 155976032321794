import * as React from "react";
import useAxios from "axios-hooks";
import config from "../config/config.js";
import { useDispatch, useSelector } from "react-redux";
import types from "./../reducers/types.js";
import Cookies from "js-cookie";

const useKidsActions = () => {
  const [userState, setUserState] = React.useState(null);
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state?.auth?.user);
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: `${config.urlServer}/kids`,
      method: "POST",
    },
    { manual: true, useCache: true }
  );

  const kidsRequest = async () => {
    const response = await executeRequest({
      data: {
        ParentId: userRedux.Id,
        Cookie: cookie,
      },
    });
    if (
      response &&
      response?.data &&
      (response?.data?.Childs?.length > 0 || response?.data?.Piggies?.length > 0)
    ) {
      dispatch({
        type: types.FETCH_KIDS_SUCCESS,
        payload: response?.data?.Childs,
      });
      dispatch({
        type: types.FETCH_KIDS_PIGGIES_SUCCESS,
        payload: response?.data?.Piggies,
      });
      dispatch({
        type: types.FETCH_KIDS_PENDING_WITHDRAWALS_SUCCESS,
        payload: response?.data?.Withdrawals,
      });
      setUserState(response?.data);
    } else {
      if (response && response?.data && response?.data?.OperationStatus === "Ok") {
        setUserState(response?.data);
      }
    }
  };

  return [userState, kidsRequest];
};

export default useKidsActions;
