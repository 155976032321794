import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import KidForm from "../Shared/KidForm.jsx";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import useBase64 from "../../hooks/useBase64";

import styles from "./edit.module.scss";

const dateCheck = (check) => {
  const from = moment(new Date()).subtract(18, 'years').format('YYYY-MM-DD').toString();
  const to = moment(new Date()).format('YYYY-MM-DD').toString();
  const checkBirth = moment(check).format('YYYY-MM-DD').toString();

  const fDate = Date.parse(from);
  const lDate = Date.parse(to);
  const cDate = Date.parse(checkBirth);

  if (cDate <= lDate && cDate >= fDate) return checkBirth;

  return moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD').toString();
};

const Edit = ({ navigate }) => {
  const { t } = useTranslation();
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/kids/edit-kid",
      method: "POST",
    },
    { manual: true }
  );
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const getBase64 = useBase64();
  const userRedux = useSelector((state) => state.auth.user);
  const kidToEdit = useSelector((state) => state.kids.selectedKid);
  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async (user) => {
    const fileBase64 =
      user && user.Photo[0] ? await getBase64(user.Photo[0]) : " ";
    const personInfo = {
      Id: kidToEdit?.Child.Id,
      FirstName: user.FirstName,
      LastName: user.LastName,
      BirthDate: dateCheck(user.BirthDate),
      Gender:
        user && user.Gender.charAt(0)
          ? user.Gender.charAt(0)
          : kidToEdit?.Child?.Gender,
      Photo:
        fileBase64 && fileBase64.length > 0
          ? fileBase64
          : kidToEdit?.Child?.Photo,
      ParentId: userRedux.Id,
    };
    const response = await executeRequest({
      data: {
        Person: { ...personInfo },
        Cookie: cookie,
      },
    });
    dispatch({
      type: types.SELECTED_KID,
      payload: { ...kidToEdit, Child: { ...personInfo } },
    });
    navigate("../");
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("editKid.title")}
          backButton={true}
          closeButton={true}
          closeButtonColor="white"
        />
        <KidForm
          styles={styles}
          loading={loading}
          onSubmit={onSubmit}
          values={kidToEdit?.Child}
        />
      </div>
    </div>
  );
};

export default Edit;
