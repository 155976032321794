import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./termsandconditions.module.scss";

const TermsAndConditions = ({ navigate }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <section className={styles.description}>
        <strong>{t("termsAndConditions.terms")}</strong>
        <br />
        <br />
        <strong>{t("termsAndConditions.important.title")}</strong>
        <br />
        <div>{t("termsAndConditions.important.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.changesTerms.title")}</strong>
        <br />
        <div>{t("termsAndConditions.changesTerms.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.legalAge.title")}</strong>
        <br />
        <div>{t("termsAndConditions.legalAge.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.legalRestriction.title")}</strong>
        <br />
        <div>{t("termsAndConditions.legalRestriction.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.managementCapital.title")}</strong>
        <br />
        <div>{t("termsAndConditions.managementCapital.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.serviceAs.title")}</strong>
        <br />
        <div>{t("termsAndConditions.serviceAs.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.useService.title")}</strong>
        <br />
        <div>{t("termsAndConditions.useService.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.serviceTermination.title")}</strong>
        <br />
        <div>{t("termsAndConditions.serviceTermination.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.atributions.title")}</strong>
        <br />
        <div>{t("termsAndConditions.atributions.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.liability.title")}</strong>
        <br />
        <div>{t("termsAndConditions.liability.text")}</div>
        <br />
        <br />
        <strong>
          {t("termsAndConditions.publicationsAndComunications.title")}
        </strong>
        <br />
        <div>{t("termsAndConditions.publicationsAndComunications.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.rightsContent.title")}</strong>
        <br />
        <div>{t("termsAndConditions.rightsContent.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.copyright.title")}</strong>
        <br />
        <div>{t("termsAndConditions.copyright.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.breachIdemnity.title")}</strong>
        <br />
        <div>{t("termsAndConditions.breachIdemnity.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.lawAndJurisdiction.title")}</strong>
        <br />
        <div>{t("termsAndConditions.lawAndJurisdiction.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.limitations.title")}</strong>
        <br />
        <div>{t("termsAndConditions.limitations.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.rightsAssignment.title")}</strong>
        <br />
        <div>{t("termsAndConditions.rightsAssignment.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.termsEntirety.title")}</strong>
        <br />
        <div>{t("termsAndConditions.termsEntirety.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.Annex.title")}</strong>
        <br />
        <div>{t("termsAndConditions.Annex.text")}</div>
        <br />
        <br />
        <strong>{t("termsAndConditions.ContactUs.title")}</strong>
        <br />
        <div>{t("termsAndConditions.ContactUs.text")}</div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
