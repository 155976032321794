import React from "react";

import internal from "./headermodal.module.scss";

function HeaderModal({
  styles,
  title,
  backButton,
  closeButton,
  navigate,
  closeButtonColor,
  tutorialClose,
  financeClose,
}) {
  return (
    <div
      className={
        styles
          ? `${internal.titleContainer} ${styles.customTitle} `
          : `${internal.titleContainer}`
      }
    >
      {backButton ? (
        <img
          className={internal.backButton}
          src="/backButton.png"
          alt="Go back"
          onClick={() => navigate("../")}
        />
      ) : (
        <span className={internal.backButton} />
      )}
      <h1
        className={
          styles ? `${internal.title} ${styles.title}` : `${internal.title}`
        }
      >
        {title}
      </h1>
      {closeButton ? (
        <img
          onClick={() => navigate("../")}
          className={internal.closeModal}
          src={
            closeButtonColor === "white"
              ? "/cancel-mark-white.png"
              : "/cancel-mark.png"
          }
          alt={`Close modal ${title}`}
        />
      ) : (
        <span className={internal.closeModal} />
      )}
      {tutorialClose && (
        <img
          onClick={tutorialClose}
          className={internal.closeModal}
          src={
            closeButtonColor === "white"
              ? "/cancel-mark-white.png"
              : "/cancel-mark.png"
          }
          alt={`Close modal ${title}`}
        />
      )}
      {financeClose && (
        <img
          onClick={financeClose}
          className={internal.closeModal}
          src={
            closeButtonColor === "white"
              ? "/cancel-mark-white.png"
              : "/cancel-mark.png"
          }
          alt={`Close modal ${title}`}
        />
      )}
    </div>
  );
}

export default React.memo(HeaderModal);
