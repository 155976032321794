import React from "react";
import { useTranslation } from "react-i18next";
import internal from "./fileinput.module.scss";
import useBase64 from "./../../hooks/useBase64.js";
import { toast } from 'react-toastify';
import { set } from "js-cookie";

const imageShow = (image, value) => {
  if (image && image !== ' ') return image;
  if (image === ' ') return '/kid_img_boy.png';
  if (value && value !== ' ') return value;
  return '/kid_img_boy.png';
};

const removeShow = (image, value) => {
  if (image && image !== ' ') return true;
  if (image === ' ') return false;
  if (value && value !== ' ') return true;
  return false;
};

const FileInput = ({
  styles,
  register,
  isRequired,
  type,
  value,
  name,
  placeholder,
  errors,
  handleRemoveImage,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = React.useState('');
  const getBase64 = useBase64();

  return (
    <div className={`${internal.container} ${styles}`}>
      <div className={internal.inputs}>
        <div className={internal.photoContent}>
          <img
            className={internal.photo}
            src={imageShow(image, value)}
            alt={placeholder}
            id='inputPhoto'
            onClick={(e) => {
              e.preventDefault();
              document.getElementById('Photo').click();
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              document.getElementById('Photo').click();
            }}
          />
          {removeShow(image, value) && (
            <div
              className={internal.removePhoto}
              onClick={() => {
                handleRemoveImage();
                setImage(' ');
              }}
              onTouchStart={() => {
                handleRemoveImage();
                setImage(' ');
              }}
            >
              {t('forms.removeImage')}
            </div>
          )}
        </div>
        <div
          className={internal.file}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('Photo').click();
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            document.getElementById('Photo').click();
          }}
        >
          {placeholder}
        </div>
        <input
          className={internal.filePhoto}
          ref={(e) => {
            register(e, { required: isRequired });
          }}
          onChange={async (e) => {
            if (e.target.files[0] && e.target.files[0].size <= 100000) {
              const fileBase64 = await getBase64(e.target.files[0]);
              setImage(fileBase64);
            } else {
              document.getElementById(name).value = null;
              toast.error(`${t('errors.sizeImage')}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }}
          accept='image/*;capture=camera'
          id={name}
          name={name}
          type={type}
          aria-invalid='true'
          aria-required='true'
          aria-invalid={errors && errors[name] ? true : false}
          aria-describedby={`${placeholder}_error`}
          aria-labelledby={`${placeholder}_error`}
          aria-label={placeholder}
          placeholder={placeholder}
          data-placeholder={placeholder}
        />
      </div>
      <span
        className={[errors && errors[name] ? internal.show : internal.hide]}
        id={`${placeholder}_error`}
        role='alert'
      >
        {placeholder} {t('forms.isRequired')}
      </span>
    </div>
  );
};

export default FileInput;
