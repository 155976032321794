import types from "./../types";

const INITIAL_STATE = {
  tasks: [],
  pendingTasks: [],
  doneTasks: [],
  rejectedTasks: [],
  loading: false,
  error: "",
  message: "",
  selectedTask: "",
  rawTasks: [],
  newTasksIds: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOADING_TASKS:
      return { ...state, loading: action.payload, error: "" };
    case types.FETCH_TASKS_SUCCESS:
      const findKidIsActive = (id) => {
        if (action.payload.Kids.length > 0) {
          return action.payload.Kids.find((item) => item.Id === id);
        } else {
          return null;
        }
      };

      const newPend = action.payload.rawTasks
        .filter((item) => {
          const found = findKidIsActive(item.ChildId);
          if (item.Status && found && found.Status) {
            return item;
          }
        })
        .reverse();

      const don = action.payload.Tasks.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (
          (item.StatusCode === "TINSTANCEAPPROVED" ||
            item.StatusCode === "TINSTANCEDONE") &&
          found &&
          found.Status
        ) {
          return item;
        }
      }).reverse();
      const reje = action.payload.Tasks.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (item.StatusCode === "TINSTANCEREJECTED" && found && found.Status) {
          return item;
        }
      }).reverse();
      return {
        ...state,
        tasks: action.payload.Tasks,
        rawTasks: action.payload.rawTasks,
        pendingTasks: [...newPend],
        doneTasks: don,
        rejectedTasks: reje,
        loading: false,
      };
    case types.DELETE_TASK_SUCCESS:
      const newPendingTasks = state.pendingTasks.filter(
        (item) => item.TaskId === action.payload
      );
      const newRawPendingTasks = state.rawTasks.filter(
        (item) => item.TaskId === action.payload
      );
      const newTasks = state.tasks.filter(
        (item) => item.TaskId === action.payload
      );
      return {
        ...state,
        tasks: newTasks,
        rawTasks: newRawPendingTasks,
        pendingTasks: newPendingTasks,
      };
    case types.UPDATE_TASK_SUCCESS:
      const taskIndex = state.tasks.findIndex(
        (item) => item.Id === action.payload.Id
      );
      const updatedTasks = [...state.tasks];
      updatedTasks[taskIndex] = action.payload;
      return { ...state, tasks: updatedTasks };
    case types.CREATE_TASK_SUCCESS:
      if (action.payload.Schedule.Type === "once") {
        return {
          ...state,
          loading: false,
          newTasksIds: action.payload.Id,
        };
      }
    case types.CLEAN_TASKS_IDS_SUCCESS:
      return {
        ...state,
        newTasksIds: null,
      };
    case types.SELECTED_TASK_SUCCESS:
      return { ...state, selectedTask: action.payload };
    case types.LOGOUT_USER_SUCCESS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
