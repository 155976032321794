import * as React from "react";
import { useTranslation } from "react-i18next";

import stylesLogos from "./../styles-general/logos.module.scss";
import stylesLogin from "./confirm-email.module.scss";

const ConfirmEmail = ({ navigate }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div tabIndex="0" className={stylesLogin.header}>
        <img
          src="/white-logo.png"
          className={stylesLogos.img}
          alt="Clinkky Admin"
        />
      </div>
      <div className={stylesLogin.loginContainer}>
        <form className={stylesLogin.loginForm}>
          <h1 className={stylesLogin.title}>
            {t("confirmEmail.confirmEmail")}
          </h1>
          <p className={stylesLogin.text}>{t("confirmEmail.description")}</p>
          <button
            onClick={() => navigate("/login")}
            tabIndex="0"
            type="button"
            className={stylesLogin.btnLogin}
          >
            {t("forgotpassword.enter")}
          </button>
          <img
            className={stylesLogin.chanchaImage}
            src="chancha-dientes-nuevos.png"
            alt="Clinkky"
          />
        </form>
      </div>
      <footer className={stylesLogin.footer}>
        <p className={stylesLogin.copyRight}>
          CLINKKY © {new Date().getFullYear()} - All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default ConfirmEmail;
