import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import useKidsActions from "./../../../Actions/KidsActions.js";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import useBase64 from "../../hooks/useBase64";

import styles from "./unsubscribe.module.scss";

const Unsubscribe = ({ navigate }) => {
  const { t } = useTranslation();
  const [activeKid, setActiveKid] = React.useState("");
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/kids/unsubscribe",
      method: "POST",
    },
    { manual: true }
  );
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const kidRedux = useSelector((state) => state.kids.selectedKid);

  const [response, kidsRequest] = useKidsActions();

  React.useEffect(() => {
    if (response && response.OperationStatus === "Ok") {
      navigate("../");
    }
  }, [response]);

  const callKidsAction = async () => {
    const responseCallAction = await kidsRequest();
  };

  React.useEffect(() => {
    if (kidRedux && kidRedux?.Id) {
      setActiveKid(kidRedux?.Id);
    }
    return () => {};
  }, [kidRedux]);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const unsubscribe = async () => {
    if (!activeKid) {
      toast.error(`${"errors.kidNoSelected"}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const response = await executeRequest({
      data: {
        PersonId: activeKid,
        Cookie: cookie,
      },
    });
    if (response?.data?.OperationStatus === "Ok") {
      toast.success(`${t("success.kidUnsubscribed")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({
        type: types.UNSUBSCRIBE_KIDS_SUCCESS,
        payload: activeKid,
      });
      callKidsAction();
    } else {
      toast.error(`${t("errors.unsubscribeKid")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t('unsubscribe.title')}
          backButton={false}
          closeButton={true}
          closeButtonColor='white'
        />
        <div className={styles.mainContent}>
          <p>{t('unsubscribe.subtitle')}</p>
          <div className={styles.kidsContainer}>
            {kidRedux && (
              <div
                key={kidRedux?.Id}
                onClick={() => setActiveKid(kidRedux?.Id)}
                className={styles.kid}
              >
                <img
                  className={styles.activeKid}
                  src='/kid_img_boy.png'
                  alt=''
                />
                <h4 className={styles.activeKidText}>
                  {kidRedux?.FirstName} {kidRedux?.LastName}
                </h4>
              </div>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <button
              onClick={async () => await unsubscribe()}
              type='button'
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t('forms.unsubscribe')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
