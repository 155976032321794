import React from "react";

import styles from "./empty.module.scss";

function Empty({ title }) {
  return (
    <div className={styles.emptyContainer}>
      <img src="/sleepychancha.png" />
      <p>{title}</p>
    </div>
  );
}

export default React.memo(Empty);
