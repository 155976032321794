import React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";
import FormTasks from "./../Shared/TasksForm/TasksForm.jsx";

import styles from "./edit-tasks.module.scss";

export default function EditTasks({ navigate }) {
  const { t } = useTranslation();
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: config.urlServer + "/tasks/edit-tasks",
      method: "POST",
    },
    { manual: true }
  );
  const cookie = Cookies.get("user");
  const kidsRedux = useSelector((state) => state.kids.kids);
  const taskSelectedRedux = useSelector((state) => state.tasks.selectedTask);
  const rawTasks = useSelector((state) => state.tasks.rawTasks);

  const { task: taskSelected } = taskSelectedRedux;

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && !!error) {
      toast.error(`${error?.response?.data?.Error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);
  const selectedKid =
    kidsRedux && kidsRedux.find((item) => taskSelected.ChildId === item.Id);
  const currentRawTask =
    rawTasks && rawTasks.find((item) => taskSelected.TaskId === item.Id);

  const onSubmit = async (task) => {
    const foundKid = kidsRedux.find(
      (item) => task.KidSelected === `${item.FirstName} ${item.LastName}`
    );
    const myTask = {
      Id: taskSelected.Id,
      Description:
        task && task.Description ? task.Description : taskSelected.Description,
      Amount: task?.Amount ? parseInt(task?.Amount) : taskSelected.Amount,
      End: "",
      Status: true,
      ChildId: foundKid?.Id,
      Schedule: {
        Type: task?.currentFrequency?.type,
        Data:
          task && task?.currentFrequency?.type === "weekly"
            ? task.currentFrequency.frequency.join(",")
            : task.currentFrequency.frequency,
      },
    };
    if (foundKid && foundKid.Id) {
      const response = await executeRequest({
        data: {
          Tasks: {
            ...myTask,
          },
          Cookie: cookie,
        },
      });
      navigate("/dashboard/tasks");
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("editTasks.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="white"
        />
        <FormTasks
          styles={styles}
          loading={loading}
          onSubmit={onSubmit}
          values={taskSelected}
          kids={kidsRedux}
        />
        <Link to="../delete" className={styles.deleteTask}>
          {t("editTasks.deleteTask")}
        </Link>
      </div>
    </div>
  );
}
