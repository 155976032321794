import React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";
import FormTasks from "./../Shared/TasksForm/TasksForm.jsx";

import styles from "./tasknew.module.scss";

export default function TaskNew({ navigate }) {
  const { t } = useTranslation();
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: config.urlServer + "/tasks/add-tasks",
      method: "POST",
    },
    { manual: true }
  );

  const dispatch = useDispatch();
  const cookie = Cookies.get("user");
  const kidsRedux = useSelector((state) => state.kids.kids);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async (task) => {
    const { Frequency, currentFrequency } = task;
    if (!Frequency || !currentFrequency) {
      toast.error(`${t("errors.taskNewFrequency")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const selectedKid = kidsRedux.find(
      (item) => task.KidSelected === `${item.FirstName} ${item.LastName}`
    );
    if (!selectedKid) {
      toast.error(`No kids...`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const response = await executeRequest({
      data: {
        Tasks: {
          Description: task.Description,
          Amount: parseInt(task.Amount),
          Schedule: {
            Type: task?.currentFrequency?.type,
            Data:
              task && task?.currentFrequency?.type === "weekly"
                ? task.currentFrequency.frequency.join(",")
                : task.currentFrequency.frequency,
          },
          End: "",
          ChildId: selectedKid.Id,
        },
        Cookie: cookie,
      },
    });
    dispatch({
      type: types.CREATE_TASK_SUCCESS,
      payload: response?.data?.Task,
    });
    navigate("/dashboard/tasks");
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("newTasks.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="white"
        />
        <FormTasks
          styles={styles}
          loading={loading}
          onSubmit={onSubmit}
          values=""
          kids={kidsRedux}
        />
      </div>
    </div>
  );
}
