import * as React from "react";
import axios from "axios";
import config from "../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import types from "../../reducers/types";
import Cookies from "js-cookie";

import styles from "./notifications.module.scss";
import RowNotifications from "./RowNotifications.jsx";
import Empty from "../Shared/Empty/Empty";

const Notifications = ({ navigate }) => {
  const [lastLocation, setLastLocation] = React.useState("../");
  const { t } = useTranslation();
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state.auth.user);
  const notifications = useSelector(
    (state) => state.notifications.notificationsNotRead
  );

  React.useEffect(() => {
    const currentLocation = localStorage.getItem("lastLocationPath");
    if (currentLocation && currentLocation.length > 0) {
      setLastLocation(currentLocation);
    }
  }, []);

  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/notifications/mark-as-read",
      method: "POST",
    },
    { manual: true }
  );

  const [
    { data: dataFetch, loading: loadingFetch, error: errorFetch },
    executeRequestFetch,
  ] = useAxios(
    {
      url: config.urlServer + "/notifications",
      method: "POST",
    },
    { manual: true }
  );

  const markAsRead = async (id) => {
    if (!id) {
      toast.error(`${t("errors.noKidDeviceId")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const response = await executeRequest({
      data: {
        MessageInboxId: id,
        Cookie: cookie,
      },
    });
    dispatch({
      type: types.MARK_NOTIFICATION_AS_READ,
      payload: id,
    });
    let timeOut = setTimeout(() => {
      refetchNotifications();
      clearTimeout(timeOut);
    }, 1000);
  };

  const markAllAsRead = async () => {
    let markAllAsReadPromises = notifications.map((itemId) => {
      return axios
        .post(
          config.urlServer + "/notifications/mark-as-read",
          {
            MessageInboxId: itemId.Id,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              cookie: `${cookie}`,
            },
          }
        )
        .then(function (response) {
          return response?.data;
        })
        .catch(function (error) {
          console.log("Error notification ", itemId);
        });
    });
    try {
      await Promise.all(markAllAsReadPromises);
      dispatch({
        type: types.FETCH_NOTIFICATIONS_SUCCESS,
        payload: [],
      });
    } catch (error) {
      console.log("Error trying to mark all notifications as read");
    }
  };

  const refetchNotifications = async () => {
    const response = await executeRequestFetch({
      data: { PersonId: userRedux.Id, Cookie: cookie },
    });

    if (
      response &&
      response?.data &&
      response?.data?.OperationStatus === "Ok" &&
      response?.data?.Messages?.length > 0
    ) {
      dispatch({
        type: types.FETCH_NOTIFICATIONS_SUCCESS,
        payload: response?.data?.Messages,
      });
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h2 className={styles.mainTitle}>{t("notifications.title")}</h2>
        </div>
        <span
          className={styles.closeButton}
          onClick={() => navigate(lastLocation)}
        >
          <img src="/cancel-mark.png" alt={t("notifications.close")} />
        </span>
        <div className={styles.notifications}>
          {notifications && notifications.length > 0 ? (
            notifications.map((item) => (
              <RowNotifications
                navigate={navigate}
                key={item.Id}
                notification={item}
                markAsRead={markAsRead}
              />
            ))
          ) : (
            <Empty title={t("notifications.empty")} />
          )}
        </div>
        <div className={styles.markAllAsRead}>
          <button className={styles.buttonAsRead} onClick={markAllAsRead}>
            {t("notifications.markAsRead")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
