import * as React from "react";
import { useTranslation } from "react-i18next";
import LinkProcesing from './LinkProcesing';
import { useSelector } from 'react-redux';
import stylesLogos from "./../../styles-general/logos.module.scss";
import styles from "./processing.module.scss";

const RequestLanding = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = React.useState(false);
  const thirdGiftUrl = useSelector((state) => state.gifts.thirdGiftUrl);
  const thirdGiftUser = useSelector((state) => state.gifts.thirdGiftUser);

  console.log(thirdGiftUser);

  React.useEffect(() => {
    let timeOut = setTimeout(() => {
      setProcessing(true);
      clearTimeout(timeOut);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div className={!processing && styles.mainContent}>
      <div tabIndex='0' className={styles.header}>
        <img
          src='/white-logo.png'
          className={stylesLogos.img}
          alt='Clinkky Admin'
        />
      </div>
      <div className={styles.loginContainer}>
        {!processing && (
          <p className={styles.linkRequestSuccess}>
            {t('giftProcessing.titleOne')}
          </p>
        )}
        {!processing && (
          <p className={styles.linkInfo}>{t('giftProcessing.subTitleOne')}</p>
        )}
        {!processing && (
          <p className={styles.linkInfo}>
            <img src='/loadingAnimated.gif' alt='Loading' />
          </p>
        )}
        {processing && (
          <div>
            <p className={styles.linkRequestSuccess}>
              {t('giftExternal.followSteps')}
            </p>
            <p className={styles.linkRequestStep}>
              {t('giftExternal.stepOne')}
            </p>
            <div className={styles.linkDataContent}>
              <div className={styles.linkDataTitle}>
                {t('giftExternal.dataTitle')}
              </div>
              <div className={styles.linkData}>
                <div className={styles.linkDataItem}>
                  <div className={styles.linkNonContainer}>
                    <p className={styles.linkLabel}>{`${t('giftExternal.accountBank')}: `}</p>
                    <p className={styles.linkNonClick}>ITAU</p>
                  </div>
                </div>
                <LinkProcesing
                  label='giftExternal.accountNumber'
                  value='2392205'
                  id='accountNumber'
                />
                <LinkProcesing
                  label='giftExternal.accountOwner'
                  value='Clinkky S.A.'
                  id='accountOwner'
                />
                <LinkProcesing
                  label='giftExternal.accountReference'
                  value={`${thirdGiftUrl?.KidId}-${thirdGiftUser?.Code}`}
                  id='accountReference'
                />
                <LinkProcesing
                  label='forms.amount'
                  value={thirdGiftUser.Amount}
                  id='amountReference'
                />
              </div>
            </div>
            <p className={styles.linkRequestStep}>
              {t('giftExternal.stepTwo')}
            </p>
            <p className={styles.linkRequestStepNote}>
              <span className={styles.linkRequestNoteS}>{t('giftExternal.stepNoteS')}</span>
              {t('giftExternal.stepNote')}
            </p>
            <p className={styles.linkRequestDone}>
              {t('giftExternal.stepDone')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestLanding;
