import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import Select from "./../../../Shared/Select/Select.jsx";
import Autocomplete from "react-autocomplete";
import BanksAlert from "../../../Banks/BanksAlert.jsx";
import autocomplete from "./form-external.module.scss";
import internal from './../../../Shared/Input/input.module.scss';

function FormExternal({ styles, loading, onSubmit, values, kids, options , handleTutorialBank }) {
  const { t } = useTranslation();
  const [motiveSelected, setMotiveSelected] = React.useState("");
  const [noApproveMessage, setNoApproveMessage] = React.useState(t("forms.helpSaving"));
  const [kidSelected, setKidSelected] = React.useState("");
  const [bankInfoAlert, setBankInfoAlert] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  React.useEffect(() => {
    return () => {};
  }, []);

  const placeholders = {
    toWhom: t("forms.toWhom"),
    motive: t("forms.motive"),
    amount: t("forms.amount"),
    character: t("forms.magicalCharacters"),
  };

  const composeSubmit = (extra) => {
    extra.Motive = motiveSelected;
    onSubmit({ ...extra });
  };

  return (
    <>
      {bankInfoAlert && <BanksAlert banksAlertClose={() => setBankInfoAlert(false)} />}
      <form id="form-request-gift" className={styles.form} onSubmit={handleSubmit(composeSubmit)}>
        <Select
          styles={styles.input}
          register={register}
          isRequired={true}
          errors={errors}
          type="text"
          name="KidSelected"
          placeholder={placeholders.toWhom}
          firstOption={placeholders.toWhom}
          optionsValues={kids}
          typeOptions="person"
          setFunction={setKidSelected}
        />
        <Autocomplete
          getItemValue={(item) => item.label}
          items={options}
          shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
          renderMenu={(items, value, styles) => {
            return items.length > 0 ? <div className={autocomplete.container} children={items}/> : <div />
          }}
          renderItem={(item, isHighlighted) =>
            <div key={item.label} className={autocomplete.item} style={{ background: isHighlighted ? 'lightgray' : 'transparent' }}>
              {item.label}
            </div>
          }
          wrapperProps={{ className: styles.input, style: { display: 'block', position: 'relative' } }}
          value={motiveSelected}
          name="Motive"
          onChange={(e) => setMotiveSelected(e.target.value)}
          onSelect={(val) => setMotiveSelected(val)}
          renderInput={(props) => {
            return (
              <>
                <input placeholder={placeholders.motive} {...props}  />
                <img className={internal.inputImage} src="/suggestEdit.png" />
              </>
            )
          }}
        />
        <div className={styles.rows}>
          <label htmlFor="">{t("forms.additionalMessage")}</label>
          <textarea
            wrap="on"
            className={`${styles.input} ${styles.textarea}`}
            ref={register({ required: true })}
            name="Message"
            id="Message"
            cols="30"
            rows="10"
            value={noApproveMessage}
            onChange={(e) => setNoApproveMessage(e.target.value)}
            placeholder={`${kidSelected} ${t("forms.helpSaving")}`}
          ></textarea>
        </div>

        <button
          type="submit"
          className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
        >
          {t("forms.createLink")}
        </button>
        <Link className={styles.goBack} to="../">
          {t("forms.goback")}
        </Link>
      </form>
    </>
  );
}

export default React.memo(FormExternal);
