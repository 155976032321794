import types from "./../types";

const INITIAL_STATE = {
  notifications: [],
  loading: false,
  error: "",
  message: "",
  notificationsNotRead: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_NOTIFICATIONS_SUCCESS:
      const notRead = action.payload.filter((item) => !item.IsRead).reverse();

      return {
        ...state,
        notificationsNotRead: notRead,
        notifications: action.payload,
      };
    case types.MARK_NOTIFICATION_AS_READ:
      const mapRead = state.notificationsNotRead.filter(
        (item) => item.Id !== action.payload && !item.IsRead
      );
      return {
        ...state,
        notificationsNotRead: mapRead,
      };
    case types.LOGOUT_USER_SUCCESS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
