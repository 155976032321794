import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import internal from "./kidaccountstatus.module.scss";

function KidAccountStatus({ kid }) {
  const { t } = useTranslation();
  const kidTransactions = useSelector((state) =>
    kid && kid.Id ? state.kids.transactions[kid.Id] : []
  );
  return (
    <div className={internal.container}>
      <h3 className={internal.title}>{t("kidAccountStatus.title")}</h3>
      <div className={internal.balance}>
        <div className={internal.concept}>
          <div className={internal.rows}>
            <h4 className={`${internal.columnTitle} ${internal.toLeft}`}>
              {t("kidAccountStatus.concept")}
            </h4>
          </div>
          {kidTransactions &&
            kidTransactions.map((item) => (
              <div key={item?.Id} className={internal.rows}>
                <h4 className={`${internal.conceptTitle} ${internal.toLeft}`}>
                  {item?.Description}
                </h4>
                <div className={internal.conceptDate}>
                  {moment(item?.Date).format("DD-MM-YYYY")}
                </div>
              </div>
            ))}
        </div>
        <div className={internal.input}>
          <div className={internal.rows}>
            <h4 className={internal.columnTitle}>
              {t("kidAccountStatus.ins")}
            </h4>
          </div>
          {kidTransactions &&
            kidTransactions.map((item) => {
              if (item.Type === "C") {
                return (
                  <div key={item?.Id} className={internal.rows}>
                    <h4 className={internal.conceptTitle}>${item?.Amount}</h4>
                  </div>
                );
              } else {
                return <div key={item?.Id} className={internal.rows}></div>;
              }
            })}
        </div>
        <div className={internal.output}>
          <div className={internal.rows}>
            <h4 className={internal.columnTitle}>
              {t("kidAccountStatus.outs")}
            </h4>
          </div>
          {kidTransactions &&
            kidTransactions.map((item) => {
              if (item.Type === "D") {
                return (
                  <div key={item?.Id} className={internal.rows}>
                    <h4 className={internal.conceptTitle}>${item.Amount}</h4>
                  </div>
                );
              } else {
                return <div key={item?.Id} className={internal.rows}></div>;
              }
            })}
        </div>
      </div>
    </div>
  );
}
export default React.memo(KidAccountStatus);
