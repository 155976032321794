import types from "./../types";
import update from 'immutability-helper';

const INITIAL_STATE = {
  kid: [],
  task: [],
  target: [],
  gift: [],
  bank: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TIP_KIDS:
      if (action.payload.show) return update(state, { kid: { $push: [action.payload.user] } });
      return update(state, { kid: arr => arr.filter(item => item !== action.payload.user) });
    case types.TIP_TASKS:
      if (action.payload.show) return update(state, { task: { $push: [action.payload.user] } });
      return update(state, { task: arr => arr.filter(item => item !== action.payload.user) });
    case types.TIP_TARGETS:
      if (action.payload.show) return update(state, { target: { $push: [action.payload.user] } });
      return update(state, { target: arr => arr.filter(item => item !== action.payload.user) });
    case types.TIP_GIFTS:
      if (action.payload.show) return update(state, { gift: { $push: [action.payload.user] } });
      return update(state, { gift: arr => arr.filter(item => item !== action.payload.user) });
    case types.TIP_BANKS:
      if (action.payload.show) return update(state, { bank: { $push: [action.payload.user] } });
      return update(state, { bank: arr => arr.filter(item => item !== action.payload.user) });
    case types.TIP_RESET:
      return update(state, {
        kid: (arr) => arr.filter((item) => item !== action.payload),
        task: (arr) => arr.filter(item => item !== action.payload),
        target: (arr) => arr.filter(item => item !== action.payload),
        gift: (arr) => arr.filter(item => item !== action.payload),
        bank: (arr) => arr.filter(item => item !== action.payload),
      });
    default:
      return state;
  }
};
