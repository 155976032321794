import types from "./../types";

const INITIAL_STATE = {
  banks: [],
  id: null,
  agency: null,
  number: '',
  holderName: '',
  holderIdentification: '',
  loading: false,
  error: "",
  message: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_BANKS_SUCCESS:
      return { ...state, banks: action.payload };
    case types.FETCH_BANK_ACCOUNT_SUCCESS:
      return { 
        ...state,
        id: action.payload?.Id,
        number: action.payload?.Number,
        agency: action.payload?.BankId,
        holderName: action.payload?.HolderName,
        holderIdentification: action.payload?.HolderIdentification,
      };
    case types.CREATE_BANK_ACCOUNT_SUCCESS:
      return { 
        ...state,
        id: action.payload.Id,
        number: action.payload.Number,
        agency: action.payload.BankId,
        holderName: action.payload.HolderName,
        holderIdentification: action.payload.HolderIdentification,
      };
    case types.UPDATE_BANK_ACCOUNT_SUCCESS:
      return { 
        ...state,
        id: action.payload.Id,
        number: action.payload.Number,
        agency: action.payload.BankId,
        holderName: action.payload.HolderName,
        holderIdentification: action.payload.HolderIdentification,
      };
    case types.SELECT_BANK:
      return { ...state, agency: action.payload };
    case types.LOGOUT_USER_SUCCESS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
