import React from "react";
import { useTranslation } from "react-i18next";
import internal from "./select.module.scss";
function Select({
  styles,
  register,
  isRequired,
  type,
  value,
  name,
  placeholder,
  errors,
  firstOption,
  optionsValues,
  typeOptions,
  repeatRequired,
  setFunction,
}) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = React.useState("");
  const ariaParse =
    placeholder.includes("?") ||
    placeholder.includes("¿") ||
    placeholder.includes(" ")
      ? placeholder?.replace("?", "").replace("¿", "").replace(" ", "")
      : placeholder;
  const renderOptions = () => {
    switch (typeOptions) {
      case "person":
        return (
          optionsValues &&
          optionsValues.map((item) => (
            <option defaultValue={item.Id} key={item.Id}>
              {item.FirstName} {item.LastName}
            </option>
          ))
        );
      case "bank":
        return (
          optionsValues &&
          optionsValues.map((item) => (
            <option value={item.Id} key={item.Id}>
              {item.Name}
            </option>
          ))
        );
      case "object":
        const optionsKeys = Object.keys(optionsValues);
        return (
          optionsKeys &&
          optionsKeys.map((item) => (
            <option defaultValue={item} key={item}>
              {optionsValues[item]}
            </option>
          ))
        );
      case "category":
        return (
          optionsValues &&
          optionsValues.map((item) => (
            <option defaultValue={item.Id} value={item.Name} key={item?.Id}>
              {item.Name}
            </option>
          ))
        );
    }
  };
  return (
    <div className={`${styles}`}>
      <select
        className={
          selectedValue?.length > 0
            ? `${internal.select} ${styles} ${internal.selected}`
            : `${internal.select} ${styles}`
        }
        name={name}
        id={name}
        ref={register({ required: isRequired })}
        onChange={(val) => {
          setFunction(val?.target?.value);
          return val.target.value;
        }}
        aria-invalid={errors && errors[name] ? true : false}
        aria-describedby={`${ariaParse}_error`}
        aria-labelledby={`${ariaParse}_error`}
        aria-label={placeholder}
        placeholder={placeholder}
        data-placeholder={placeholder}
      >
        <option value='' className={internal.firstOption} key='initial'>
          {firstOption}
        </option>
        {renderOptions()}
      </select>
      <br />
      <span
        className={errors && errors[name] ? internal.show : internal.hide}
        id={`${ariaParse}_error`}
        role='alert'
      >
        {repeatRequired
          ? `${t('forms.frequencyRequired')}`
          : `${placeholder} ${t('forms.isRequired')}`}
      </span>
    </div>
  );
}
export default React.memo(Select);
