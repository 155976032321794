import * as React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "@reach/router";
import HeaderModal from "../Shared/HeaderModal/HeaderModal.jsx";
import types from "../../reducers/types";
import styles from "./tutorials.module.scss";

const Tutorials = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state.auth.user);
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    handleTutorialChange();
  }, [location]);

  const handleTutorialChange = () => {
    switch (location.pathname) {
      case "/dashboard":
        setTitle(t("tutorial.titles.kids"));
        setMessage(t("tutorial.messages.kids"));
        break;
      case "/dashboard/tasks":
        setTitle(t("tutorial.titles.tasks"));
        setMessage(t("tutorial.messages.tasks"));
        break;
      case "/dashboard/targets":
        setTitle(t("tutorial.titles.targets"));
        setMessage(t("tutorial.messages.targets"));
        break;
      case "/dashboard/gifts":
        setTitle(t("tutorial.titles.gifts"));
        setMessage(t("tutorial.messages.gifts"));
        break;
      case "/dashboard/gifts/third":
        setTitle(t("tutorial.titles.bankAccount"));
        setMessage(t("tutorial.messages.bankAccount"));
        break;
      default:
        break;
    }
  };

  const handleTutorialManage = () => {
    switch (location.pathname) {
      case "/dashboard":
        dispatch({
          type: types.TIP_KIDS,
          payload: {show: true, user: userRedux?.Id},
        });
        break;
      case "/dashboard/tasks":
        dispatch({
          type: types.TIP_TASKS,
          payload: {show: true, user: userRedux?.Id},
        });
        break;
      case "/dashboard/targets":
        dispatch({
          type: types.TIP_TARGETS,
          payload: {show: true, user: userRedux?.Id},
        });
        break;
      case "/dashboard/gifts":
        dispatch({
          type: types.TIP_GIFTS,
          payload: {show: true, user: userRedux?.Id},
        });
      case "/dashboard/gifts/third":
        dispatch({
          type: types.TIP_BANKS,
          payload: {show: true, user: userRedux?.Id},
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={title}
          backButton={false}
          closeButton={false}
          closeButtonColor="white"
          tutorialClose={handleTutorialManage}
        />
        <div className={styles.tutorialMessage}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default Tutorials;