import types from "../types";

const INITIAL_STATE = {
  show: false,
  title: '',
  content: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.NEWS_SHOW:
      return { ...state, show: action.payload };
    case types.NEWS_INFO:
      const { title, content } = action.payload;
      return { ...state, title, content };
    default:
      return state;
  }
};
