import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import useKidsActions from "./../../../Actions/KidsActions.js";

import Empty from "./../../Shared/Empty/Empty.jsx";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./enable.module.scss";

const Enable = ({ navigate }) => {
  const { t } = useTranslation();
  const [activeKid, setActiveKid] = React.useState("");
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/kids/kid-status",
      method: "POST",
    },
    { manual: true }
  );
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state.auth.user);
  const kidRedux = useSelector((state) => state.kids.kidsDisabled);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const [response, kidsRequest] = useKidsActions();

  React.useEffect(() => {
    if (response && response.OperationStatus === "Ok") {
      navigate("../");
    }
  }, [response]);

  const callKidsAction = async () => {
    const responseCallAction = await kidsRequest();
  };

  const checkSelectedKid = () => {
    if (!activeKid) {
      toast.error(`${t("errors.kidNoSelected")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const selectedKid = (kid) => {
    setActiveKid(kid?.Id);
    dispatch({
      type: types.SELECTED_KID,
      payload: kid,
    });
  };

  const goUnsubscribe = () => {
    if (checkSelectedKid()) {
      navigate("../unsubscribe-kid");
    }
  };

  const onSubmit = async () => {
    if (!checkSelectedKid()) {
      return false;
    }
    const response = await executeRequest({
      data: {
        Id: activeKid,
        Status: "true",
        Cookie: cookie,
      },
    });
    if (response?.data?.OperationStatus === "Ok") {
      toast.success(`${t("success.kidEnable")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({
        type: types.STATUS_KIDS_SUCCESS,
        payload: response?.data,
      });
      callKidsAction();
    } else {
      toast.error(`${t("errors.kidEnable")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t('enableKids.title')}
          backButton={false}
          closeButton={true}
          closeButtonColor='white'
        />
        <div className={styles.mainContent}>
          <p>{t('enableKids.subTitle')}</p>
          <div className={styles.kidsContainer}>
            {userRedux && kidRedux && kidRedux?.length === 0 && (
              <Empty title={t('enableKids.emptyTitle')} />
            )}
            {userRedux &&
              kidRedux &&
              kidRedux?.length >= 1 &&
              kidRedux?.map((item) => (
                <div
                  key={item.Id}
                  onClick={() => selectedKid(item)}
                  className={styles.kid}
                >
                  <img
                    className={
                      item?.Id === activeKid
                        ? styles.activeKid
                        : styles.kidImage
                    }
                    src='/kid_img_boy.png'
                    alt=''
                  />
                  <h4
                    className={
                      item?.Id === activeKid
                        ? styles.activeKidText
                        : styles.kidText
                    }
                  >
                    {item.FirstName} {item.LastName}
                  </h4>
                </div>
              ))}
          </div>
          <div className={styles.buttonsContainer}>
            <button
              type='button'
              onClick={() => onSubmit()}
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t('forms.enableAgain')}
            </button>
            <button
              onClick={() => goUnsubscribe()}
              type='button'
              className={styles.btnSecond}
            >
              {t('forms.unsubscribe')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enable;
