import React from "react";
import { useLocation } from "@reach/router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import types from '../../../reducers/types';
import Input from "../../Shared/Input/Input.jsx";
import Date from "../../Shared/Date/Date.jsx";
import FileInput from "../../Shared/FileInput/FileInput.jsx";

import useBase64 from "./../../hooks/useBase64.js";

function KidForm({ styles, loading, onSubmit, values }) {
  const [sex, setSex] = React.useState("");
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const locationsToShow = ["/dashboard/details-kids/edit-kids"];
  const shouldDisplayButton = locationsToShow.includes(location.pathname);
  const placeholders = {
    name: t("forms.firstName"),
    lastName: t("forms.lastName"),
    birthdate: t("forms.birthday"),
    photo: t("forms.loadImage"),
    sexo: t("forms.sex"),
  };

  const sexObj = {
    woman: t("forms.female"),
    man: t("forms.male"),
  };

  React.useEffect(() => {
    if (values && values.Gender) {
      if (values.Gender === "M") {
        setSex(t("forms.male"));
      } else {
        setSex(t("forms.female"));
      }
    } else {
      setSex(t("forms.male"));
    }
  }, []);

  const doSubmit = (user) => {
    onSubmit({ ...user, Gender: sex.charAt(0) });
  };

  const handleRemoveImage = () => {
    if (values) {
      dispatch({
        type: types.REMOVE_KID_PHOTO,
        payload: values?.Id,
      });
    }
  };

  return (
    <form
      id='form-add-kid'
      className={styles.form}
      onSubmit={handleSubmit(doSubmit)}
    >
      <FileInput
        styles={styles.input}
        register={register}
        isRequired={false}
        errors={errors}
        value={values?.Photo}
        type='file'
        name='Photo'
        placeholder={placeholders.photo}
        handleRemoveImage={handleRemoveImage}
      />
      <Input
        styles={styles.input}
        register={register}
        isRequired={values && values.FirstName ? false : true}
        errors={errors}
        value={values?.FirstName}
        type='text'
        name='FirstName'
        placeholder={placeholders.name}
      />
      <Input
        styles={styles.input}
        register={register}
        isRequired={values && values.LastName ? false : true}
        errors={errors}
        value={values?.LastName}
        type='text'
        name='LastName'
        placeholder={placeholders.lastName}
      />
      <Date
        styles={styles.input}
        register={register}
        isRequired={values && values.BirthDate ? false : true}
        errors={errors}
        value={values?.BirthDate}
        type='date'
        name='BirthDate'
        placeholder={placeholders.birthdate}
      />
      <div className={styles.sexRadioContainer}>
        <p className={styles.sexLabel}>{t('forms.gender')}:</p>
        <div className={styles.sexInputs}>
          <div
            className={styles.radioContainer}
            onClick={() => {
              setSex(sexObj['man']);
            }}
          >
            <input
              ref={register({ required: false })}
              type='radio'
              id={sexObj['man']}
              name='Gender'
              value={sexObj['man']}
            />
            <img
              src={
                sex === t('forms.male')
                  ? '/radiobuttonActive.png'
                  : '/radiobutton.png'
              }
              className={styles.radioButtons}
              onClick={() => {
                setSex(sexObj['man']);
              }}
            />
            <label
              onClick={() => {
                setSex(sexObj['man']);
              }}
              htmlFor={sexObj['man']}
            >
              {sexObj['man']}
            </label>
          </div>
          <div
            className={styles.radioContainer}
            onClick={() => {
              setSex(sexObj['woman']);
            }}
          >
            <img
              src={
                sex === t('forms.female')
                  ? '/radiobuttonActive.png'
                  : '/radiobutton.png'
              }
              className={styles.radioButtons}
              onClick={() => {
                setSex(sexObj['woman']);
              }}
            />
            <input
              ref={register({ required: false })}
              type='radio'
              id={sexObj['woman']}
              name='Gender'
              value={sexObj['woman']}
            />
            <label
              onClick={() => {
                setSex(sexObj['woman']);
              }}
              htmlFor={sexObj['woman']}
            >
              {sexObj['woman']}
            </label>
          </div>
        </div>
      </div>
      <button
        type='submit'
        className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
      >
        {shouldDisplayButton ? t('forms.edit') : t('forms.create')}
      </button>
    </form>
  );
}

export default React.memo(KidForm);
