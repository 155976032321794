import React from "react";

import internal from "./rowlistrounded.module.scss";

function RowListRounded({ children, title, price }) {
  const [toggleState, setToggleState] = React.useState(false);
  return (
    <div
      onClick={() => setToggleState(!toggleState)}
      className={
        toggleState
          ? `${internal.container} ${internal.open}`
          : `${internal.container} ${internal.close}`
      }
    >
      <div
        className={
          toggleState
            ? `${internal.title} ${internal.titleActive}`
            : `${internal.title}`
        }
      >
        {title}
        <span className={internal.priceArrow}>
          {price}
          <img
            className={toggleState ? internal.imgUp : internal.imgDown}
            src="/arrowup.png"
            alt="Abre o cierra Retiros pendientes"
          />
        </span>
      </div>
      <div className={toggleState ? internal.content : internal.empty}>
        {toggleState && children}
      </div>
    </div>
  );
}

export default React.memo(RowListRounded);
