import types from "./../types";

const INITIAL_STATE = {
  targets: [],
  loading: false,
  error: "",
  message: "",
  selectedTarget: null,
  targetsPending: [],
  targetsApprove: [],
  targetsCompleted: [],
  targetsRejected: [],
  targetsCategories: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_TARGETS_SUCCESS:
      const findKidIsActive = (id) =>
        action.payload.Kids.find((item) => item.Id === id);
      const pend = action.payload.Targets.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (item.StatusCode === "TARGETPENDING" && found && found.Status) {
          return item;
        }
      }).reverse();
      const approve = action.payload.Targets.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (item.StatusCode === "TARGETAPPROVED" && found && found.Status) {
          return item;
        }
      }).reverse();
      const completed = action.payload.Targets.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (item.StatusCode === "TARGETCOMPLETED" && found && found.Status) {
          return item;
        }
      }).reverse();
      const reje = action.payload.Targets.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (item.StatusCode === "TARGETREJECTED" && found && found.Status) {
          return item;
        }
      }).reverse();
      return {
        ...state,
        targets: action.payload,
        targetsPending: pend,
        targetsApprove: approve,
        targetsCompleted: completed,
        targetsRejected: reje,
      };
    case types.FETCH_TARGETS_CATEGORIES_SUCCESS:
      return { ...state, targetsCategories: action.payload };
    case types.SELECTED_TARGET_SUCCESS:
      return { ...state, selectedTarget: action.payload };
    case types.LOGOUT_USER_SUCCESS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
