/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import types from './../../../reducers/types';
import stylesLogos from "./../../styles-general/logos.module.scss";
import styles from "./request-landing.module.scss";
import Input from "../../Shared/Input/Input";
import Autocomplete from "react-autocomplete";
import autocomplete from "./../Shared/FormExternal/form-external.module.scss";
import internal from './../../Shared/Input/input.module.scss';

const RequestLanding = ({ navigate }) => {
  const { t } = useTranslation();
  const [characterSelected, setCharacterSelected] = React.useState("");
  const [{ data, loading, error },  executeRequest,] = useAxios(
    {
      url: config.urlServer + "/gifts/create-gift-third",
      method: "POST",
    },
    { manual: true }
  );
  const optionsValuesMotives = t("magicCharacters", { returnObjects: true });
  const placeholders = {
    fromWhom: t("forms.fromWhom"),
    firstName: t("forms.firstName"),
    lastName: t("forms.lastName"),
    character: t("forms.magicalCharacters"),
    motive: t("forms.motive"),
    amount: t("forms.amount"),
    mail: t("forms.email"),
  };
  const cookie = Cookies.get("user");
  const thirdGiftUrl = useSelector((state) => state.gifts.thirdGiftUrl);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (dataForm) => {
    dataForm.Character = characterSelected;

    const { FromWho, FirstName, LastName, Character, Amount, Mail } = dataForm;
    if (!FirstName || !LastName) {
      toast.error(`${t("giftRequestLanding.errorFrom")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    
    const response = await executeRequest({
      data: {
        Donor: {
          DocumentNumber: "11111111111",
          Mail: Mail,
          FirstName: FirstName,
          LastName: LastName,
          PhoneNumber: "1994846490",
        },
        CreditCard: {
          Name: `${FirstName.split(" ")[0]} ${LastName.split(" ")[0]}`,
          Number: "4111111111111111",
          CVV: "239",
          Expiration: "09/25",
        },
        Gift: {
          ChildId: thirdGiftUrl.KidId,
          Amount: Amount,
          TotalAmount: Amount,
          Motive: handleStringsUrl(thirdGiftUrl.Motive),
          Message: handleStringsUrl(thirdGiftUrl.Message),
        },
        Cookie: cookie,
      },
    });
    const Code = Date.now();

    await axios.post(`${config.urlServer}/send-notification`, {
      labelAccountBank: t('giftExternal.accountBank'),
      labelAccountNumber: t('giftExternal.accountNumber'),
      labelAccountOwner: t('giftExternal.accountOwner'),
      labelAccountCode: t('giftExternal.accountReference'),
      labelAmount: placeholders.amount,
      infoAccountCode: `${thirdGiftUrl.KidId}-${Code}`,
      infoFirstName: FirstName,
      infoLastName: LastName,
      infoMail: Mail,
      infoAmount: Amount,
      tagMailTitle: t('giftExternal.mail.title'),
      tagMailSubtitle: t('giftExternal.mail.subtitle'),
      tagMailNote: t('giftExternal.mail.note'),
    });

    dispatch({
      type: types.THIRD_GIFTS_USER,
      payload: { Mail, FirstName, LastName, Code, Amount },
    });
    navigate("/processing-gift-third");
    toast.success(`${t("giftRequestLanding.giftCreated")}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleStringsUrl = (datStrings) => {
    if (datStrings) return datStrings.split("-").join(" ");
    return '';
  };

  return (
    <div className={styles.mainContent}>
      <div tabIndex="0" className={styles.header}>
        <img
          src="/white-logo.png"
          className={stylesLogos.img}
          alt="Clinkky Admin"
        />
      </div>
      <div className={styles.loginContainer}>
        <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
          <h1 className={styles.title}>{t("giftRequestLanding.title")}</h1>
          <p className={styles.text}>
            {`${t("giftRequestLanding.subTitleOne")} 
            ${handleStringsUrl(thirdGiftUrl?.KidName)}? ${t("giftRequestLanding.subTitleTwo")}`}
          </p>
          <p className={styles.Motives}>
            {t("forms.motive")}: {handleStringsUrl(thirdGiftUrl?.Motive)}
          </p>
          <p className={styles.Motives}>{placeholders.fromWhom}</p>
          <div className={styles.firstLastName}>
            <Input
              styles={styles.input}
              register={register}
              isRequired={true}
              errors={errors}
              type="text"
              value=""
              name="FirstName"
              placeholder={placeholders.firstName}
            />
            <Input
              styles={styles.input}
              register={register}
              isRequired={true}
              errors={errors}
              type="text"
              value=""
              name="LastName"
              placeholder={placeholders.lastName}
            />
          </div>
          <p className={styles.Message}>
            {`${t("giftRequestLanding.descriptionOne")} 
            ${handleStringsUrl(thirdGiftUrl?.KidName)} 
            ${t("giftRequestLanding.descriptionTwo")}`}
          </p>
          <Input
            styles={styles.input}
            register={register}
            isRequired={true}
            errors={errors}
            type="text"
            value=""
            name="Mail"
            placeholder={placeholders.mail}
          />
          <Autocomplete
            getItemValue={(item) => item.label}
            items={optionsValuesMotives}
            shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
            renderMenu={(items, value, styles) => {
              return items.length > 0 ? <div className={autocomplete.container} children={items}/> : <div />
            }}
            renderItem={(item, isHighlighted) =>
              <div key={item.label} className={autocomplete.item} style={{ background: isHighlighted ? 'lightgray' : 'transparent' }}>
                {item.label}
              </div>
            }
            wrapperProps={{ className: styles.input, style: { display: 'block', position: 'relative' } }}
            value={characterSelected}
            name="Motive"
            onChange={(e) => setCharacterSelected(e.target.value)}
            onSelect={(val) => setCharacterSelected(val)}
            renderInput={(props) => {
              return (
                <>
                  <input placeholder={placeholders.character} {...props}  />
                  <img className={internal.inputImage} src="/suggestEdit.png" />
                </>
              )
            }}
          />
          <Input
            styles={styles.input}
            register={register}
            isRequired={true}
            errors={errors}
            type="text"
            value=""
            name="Amount"
            placeholder={placeholders.amount}
          />
          <button
            type="submit"
            className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
          >
            {t("giftRequestLanding.depositGift")}
          </button>
          <img
            className={styles.chanchaImage}
            src="chancha-dientes-nuevos.png"
            alt="Clinkky"
          />
        </form>
      </div>
      <footer className={styles.footer}>
        <p className={styles.copyRight}>
          CLINKKY © {new Date().getFullYear()} - All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default RequestLanding;
