import * as React from "react";
import { useParams } from "@reach/router";
import stylesLogos from "./../styles-general/logos.module.scss";
import styles from "./initialloading.module.scss";

import useKidsActions from "./../../Actions/KidsActions.js";

const InitialLoading = ({ navigate }) => {
  let { id } = useParams();

  const [response, kidsRequest] = useKidsActions();

  React.useEffect(() => {
    if (!!!response) {
      callKidsAction();
    }
    if (response && response.OperationStatus === "Ok") {
      navigate("/dashboard");
    }
  }, [response]);

  const callKidsAction = async () => {
    const responseCallAction = await kidsRequest();
  };

  return (
    <div>
      <div className={styles.header}>
        <img
          src="/white-logo.png"
          className={stylesLogos.img}
          alt="Clinkky Admin"
        />
      </div>
      <div className={styles.loginContainer}>
        <form className={styles.loginForm}>
          <h1 tabIndex="0" className={styles.title}></h1>
          <div className={styles.chanchaContainer}>
            <img
              className={styles.chancha}
              src="/clinkky_sin_ocico.png"
              alt="Loading"
            />
            <img
              className={styles.chanchaHocico}
              src="/hocico.png"
              alt="Loading"
            />
          </div>
        </form>
      </div>
      <footer className={styles.footer}>
        <p className={styles.copyRight}>
          CLINKKY © {new Date().getFullYear()} - All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default InitialLoading;
