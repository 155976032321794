import React from "react";
import config from "../../../config/config";
import { useForm } from "react-hook-form";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "@reach/router";
import Cookies from "js-cookie";
import Cleave from "cleave.js";
import types from '../../../reducers/types';
import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";
import FileInput from "../../Shared/FileInput/FileInput.jsx";
import Input from "../../Shared/Input/Input.jsx";
import Select from "../../Shared/Select/Select.jsx";

import styles from "./approve-reject.module.scss";

import useTargetsActions from "./../../../Actions/TargetsActions.js";

export default function ApproveReject({ navigate }) {
  const [category, setCategory] = React.useState("");
  const [buttonState, setButtonState] = React.useState("");
  const [categorySelected, setCategorySelected] = React.useState("");
  const [noApproveMessage, setNoApproveMessage] = React.useState("");
  const [noApprove, setNoApprove] = React.useState(false);
  const [targetCompleted, setTargetCompleted] = React.useState(false);
  const { register, handleSubmit, setValue, errors } = useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const locationsToShow = ["/dashboard/details-kids/edit-kids"];
  const shouldDisplayButton = locationsToShow.includes(location.pathname);
  const [responseTargets, targetsRequest] = useTargetsActions();
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: config.urlServer + "/targets/approve-target",
      method: "POST",
    },
    { manual: true }
  );
  const [
    { data: dataReject, loading: loadingReject, error: errorReject },
    executeRequestReject,
  ] = useAxios(
    {
      url: config.urlServer + "/targets/reject-target",
      method: "POST",
    },
    { manual: true }
  );
  const [
    { data: dataEdit, loading: loadingEdit, error: errorEdit },
    executeRequestEdit,
  ] = useAxios(
    {
      url: config.urlServer + "/targets/update-target",
      method: "POST",
    },
    { manual: true }
  );

  const placeholders = {
    title: t("forms.title"),
    amount: t("forms.amount"),
    category: t("forms.category"),
  };

  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state.auth.user);
  const kidsRedux = useSelector((state) => state.kids.kids);
  const selectedTarget = useSelector((state) => state.targets.selectedTarget);
  const targetsCategoriesRedux = useSelector(
    (state) => state.targets.targetsCategories
  );

  React.useEffect(() => {
    const found = targetsCategoriesRedux.find(
      (item) => item?.Name === category
    );
    if (found && found.Id) {
      setCategorySelected({ ...found });
      setCategory(found.Name);
      setValue("Category", found?.Name);
      let timeout = setTimeout(() => {
        setCategory(found.Name);
        setValue("Category", found?.Name);
        clearTimeout(timeout);
      }, 1);
    }
  }, [category]);

  React.useEffect(() => {
    let amountCleave = new Cleave("#Goal", {
      numeral: true,
      delimiter: "",
      numeralThousandsGroupStyle: "thousand",
    });
  }, []);

  React.useEffect(() => {
    const found = targetsCategoriesRedux.find(
      (item) => item?.Id === selectedTarget?.target?.TargetCategoryId
    );
    if (found && found.Id) {
      setCategorySelected({ ...found });
      setCategory(found.Name);
      setValue("Category", found?.Name);
      let timeout = setTimeout(() => {
        setCategory(found.Name);
        setValue("Category", found?.Name);
        clearTimeout(timeout);
      }, 1);
    }

    if (
      selectedTarget &&
      selectedTarget.target &&
      selectedTarget.target.StatusCode === "TARGETCOMPLETED"
    ) {
      setTargetCompleted(true);
    }
  }, [selectedTarget]);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async (data) => {
    let response = null;
    if (buttonState === "reject") {
      response = await executeRequestReject({
        data: {
          Id: selectedTarget?.target?.Id,
          Cookie: cookie,
        },
      });
    }
    if (buttonState === "approve") {
      response = await executeRequest({
        data: {
          Id: selectedTarget?.target?.Id,
          Cookie: cookie,
        },
      });
    }
    if (buttonState === "edit") {
      response = await executeRequestEdit({
        data: {
          Target: {
            Id: selectedTarget?.target?.Id,
            Name: data?.Name,
            Goal: data?.Goal,
            Image: "",
          },
          Cookie: cookie,
        },
      });
    }
    await response;
    await targetsRequest();
    navigate("/dashboard/targets");
  };
  const handleRemoveImage = () => {
    dispatch({
      type: types.REMOVE_PROFILE_PHOTO,
    });
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={
            selectedTarget &&
            selectedTarget.target &&
            selectedTarget.target.StatusCode === 'TARGETCOMPLETED'
              ? `${selectedTarget.target.Name} ${t('targets.done')}`
              : selectedTarget.target.StatusCode === 'TARGETAPPROVED'
              ? `${selectedTarget.target.Name} ${t('targets.doing')}`
              : `${t('targetsApproveReject.title')}`
          }
          backButton={false}
          closeButton={true}
          closeButtonColor='white'
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            selectedTarget &&
            selectedTarget.target &&
            selectedTarget.target.StatusCode !== 'TARGETCOMPLETED'
              ? styles.form
              : `${styles.form} ${styles.disableEvents}`
          }
        >
          <FileInput
            styles={`${styles.input} ${styles.fileInput}`}
            register={register}
            isRequired={false}
            errors={errors}
            value={userRedux && userRedux.Photo ? userRedux.Photo : undefined}
            type='file'
            name='Image'
            placeholder={`${selectedTarget?.kid?.FirstName} ${selectedTarget?.kid?.LastName}`}
            handleRemoveImage={handleRemoveImage}
          />
          {!targetCompleted && (
            <div className={styles.rows}>
              <p>
                {t('targetsApproveReject.descriptionOne')}{' '}
                {selectedTarget?.kid?.FirstName} {selectedTarget?.kid?.LastName}{' '}
                {t('targetsApproveReject.descriptionTwo')}{' '}
                {selectedTarget?.target?.Name}
              </p>
            </div>
          )}
          {!targetCompleted ? (
            <React.Fragment>
              <Input
                styles={styles.input}
                register={register}
                isRequired={true}
                errors={errors}
                value={
                  selectedTarget &&
                  selectedTarget.target &&
                  selectedTarget.target.Name
                    ? selectedTarget?.target?.Name
                    : undefined
                }
                type='text'
                name='Name'
                placeholder={placeholders.title}
              />
              <Input
                styles={styles.input}
                register={register}
                isRequired={true}
                errors={errors}
                value={
                  selectedTarget && selectedTarget.target.Goal
                    ? selectedTarget?.target?.Goal
                    : undefined
                }
                type='text'
                name='Goal'
                placeholder={selectedTarget?.target?.Goal}
              />
              <div className={styles.rows}>
                <div className={styles.kidsContainer}>
                  <div key={categorySelected?.Id} className={styles.kid}>
                    <img
                      className={
                        categorySelected && categorySelected?.Image
                          ? styles.activeKid
                          : styles.kidImage
                      }
                      src={
                        categorySelected &&
                        categorySelected.Image &&
                        categorySelected.Image !== ' '
                          ? categorySelected?.Image
                          : '/kid_img_boy.png'
                      }
                      alt={categorySelected?.Name}
                    />
                    <Select
                      styles={styles.input}
                      register={register}
                      isRequired={true}
                      errors={errors}
                      type='text'
                      name='Category'
                      placeholder={placeholders.category}
                      firstOption={placeholders.category}
                      optionsValues={[...targetsCategoriesRedux]}
                      typeOptions='category'
                      setFunction={setCategory}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {' '}
              <div className={styles.rows}>
                <h2>
                  {placeholders.title}:
                  <span>{selectedTarget?.target?.Name}</span>
                </h2>
              </div>
              <div className={styles.rows}>
                <h2 className>
                  {placeholders.amount}:
                  <span>{selectedTarget?.target?.Goal}</span>
                </h2>
              </div>
            </React.Fragment>
          )}
          {selectedTarget &&
            selectedTarget.target &&
            selectedTarget.target.StatusCode === 'TARGETPENDING' && (
              <div className={styles.orderButtons}>
                <div
                  className={
                    noApprove
                      ? `${styles.buttonContainer} ${styles.orderTwo}`
                      : `${styles.buttonContainer} ${styles.orderOne}`
                  }
                >
                  <button
                    onClick={() => setButtonState('approve')}
                    type='submit'
                    className={[
                      loading ? styles.btnLoginDisabled : styles.btnLogin,
                    ]}
                  >
                    {t('forms.approve')}
                  </button>
                </div>
                <br />
                <div
                  className={
                    noApprove
                      ? `${styles.buttonContainer} ${styles.orderOne}`
                      : `${styles.buttonContainer} ${styles.orderTwo}`
                  }
                >
                  <button
                    onClick={() => {
                      setButtonState('reject');
                    }}
                    type='submit'
                    className={[
                      loading ? styles.btnLoginDisabled : styles.btnSecond,
                    ]}
                  >
                    {t('forms.noApprove')}
                  </button>
                </div>
              </div>
            )}
          {selectedTarget &&
            selectedTarget.target &&
            selectedTarget.target.StatusCode === 'TARGETAPPROVED' && (
              <div className={styles.orderButtons}>
                <div
                  className={
                    noApprove
                      ? `${styles.buttonContainer} ${styles.orderTwo}`
                      : `${styles.buttonContainer} ${styles.orderOne}`
                  }
                >
                  <button
                    onClick={() => setButtonState('edit')}
                    type='submit'
                    className={[
                      loading ? styles.btnLoginDisabled : styles.btnLogin,
                    ]}
                  >
                    {t('forms.edit')}
                  </button>
                </div>
              </div>
            )}
        </form>
      </div>
    </div>
  );
}
