import React from "react";
import useAxios from "axios-hooks";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import types from "../../../reducers/types";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import moment from "moment";
import esLocale from "moment/locale/es";
import ptLocale from "moment/locale/pt";
import config from "../../../config/config";
import Input from "./../../Shared/Input/Input.jsx";
import HeaderModal from "./../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./withdraw.module.scss";

const handleLangTime = () => {
  const lang = localStorage.getItem("i18nextLng");
  if (!lang) return "es";
  return lang.split("-")[0];
};

export default function Withdraw({ navigate, kid }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/withdrawal/approve-withdrawal",
      method: "POST",
    },
    { manual: true }
  );
  const placeholders = {
    motivo: t("forms.motive"),
  };
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const kidRedux = useSelector((state) => state.kids.selectedKid);
  moment.locale(handleLangTime(), [ptLocale, esLocale]);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);
  const onSubmit = async (withdraw) => {
    const { Motive } = withdraw;
    const response = await executeRequest({
      data: {
        Id: kidRedux?.withdraw?.Id,
        Cookie: cookie,
      },
    });
    navigate("/dashboard");
    window.location.reload();
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t('withdraw.title')}
          backButton={false}
          closeButton={true}
          closeButtonColor='red'
        />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.mainContent}>
          <div className={styles.row}>
            <img
              className={styles.kidPhoto}
              src={
                kidRedux?.Child && kidRedux?.Child?.Photo && kidRedux?.Child?.Photo !== ' '
                  ? kidRedux?.Child?.Photo
                  : `/kid_img_boy.png`
              }
              alt={`${kidRedux?.Child?.FirstName} ${kidRedux?.Child?.LastName}`}
            />
          </div>
          <div className={styles.rowInfoContent}>
            <div className={styles.rowInfo}>
              <div className={styles.contentInfo}>
                <div className={styles.rowInfoName}>{kidRedux?.Child?.FirstName}</div>
                <div className={styles.rowInfoAmount}>${kidRedux?.withdraw?.Amount}</div>
              </div>
              <div className={styles.contentDesc}>
                {kidRedux?.withdraw?.Description}
              </div>
              <div className={styles.contentDate}>
                {moment(kid?.withdraw?.CreateDate).format('ll')}
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <p>
              {kidRedux?.Child?.FirstName} {t('withdraw.isRequesting')}
              {' $'}
              {kidRedux?.withdraw?.Amount} {t('withdraw.toDeliverMoney')}
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <button
              type='submit'
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t('forms.deliver')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
