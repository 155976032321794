import React, { Suspense } from "react";
import { Router } from "@reach/router";
import { useTranslation } from "react-i18next";
import Loading from "./Components/Shared/Loading/Loading.jsx";
import SignupKids from "./Components/Kids/Add/Add.jsx";
import EditKids from "./Components/Kids/Edit/Edit.jsx";
import PairKids from "./Components/Kids/Pair/Pair.jsx";
import WithdrawKids from "./Components/Kids/Withdraw/Withdraw.jsx";
import DisableKids from "./Components/Kids/Disable/Disable.jsx";
import EnableKids from "./Components/Kids/Enable/Enable.jsx";
import UnsubscribeKids from "./Components/Kids/Unsubscribe/Unsubscribe.jsx";
import KidDetails from "./Components/Kids/Shared/KidDetails.jsx";

import TaskNew from "./Components/Tasks/New/TaskNew.jsx";
import TaskEdit from "./Components/Tasks/Edit/EditTasks.jsx";
import TaskDelete from "./Components/Tasks/Delete/DeleteTask.jsx";
import TaskApprove from "./Components/Tasks/Approve/ApproveTask.jsx";
import TaskReject from "./Components/Tasks/Reject/RejectTask.jsx";

import ExternalGift from "./Components/Gifts/External/ExternalGift.jsx";
import InternalGift from "./Components/Gifts/Internal/InternalGift.jsx";
import ApproveNotApprove from "./Components/Gifts/ExternalApproveNotApprove/ApproveNotApprove.jsx";
import VerifyParent from "./Components/Gifts/VerifyParent/VerifyParent.jsx";
import GiftLinks from "./Components/Gifts/GiftLinks/GiftLinks.jsx";
import RequestLanding from "./Components/Gifts/RequestLanding/RequestLanding.jsx";
import LoadingLanding from "./Components/Gifts/LoadingLanding/LoadingLanding.jsx";
import Processing from "./Components/Gifts/Processing/Processing.jsx";
import ApproveReject from "./Components/Targets/ApproveReject/ApproveReject.jsx";
import ConfirmEmail from "./Components/Signup/ConfirmEmail.jsx";
import Notifications from "./Components/Notifications/Notifications.jsx";
import InitialLoading from "./Components/InitialLoading/InitialLoading.jsx";
import KidAddedPaired from "./Components/Kids/KidAddedPaired/KidAddedPaired.jsx";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions.jsx";

const Profile = React.lazy(() => import("./Components/Profile/Profile.jsx"));
const Login = React.lazy(() => import("./Components/Login/Login.jsx"));
const SignupInfo = React.lazy(() =>
  import("./Components/Signup/SignupInfo.jsx")
);
const LoginWithDevice = React.lazy(() =>
  import("./Components/LoginWithDevice/LoginWithDevice.jsx")
);
const Confirmation = React.lazy(() =>
  import("./Components/Confirmation/Confirmation.jsx")
);
const Dashboard = React.lazy(() =>
  import("./Components/Dashboard/Dashboard.jsx")
);
const My404Component = React.lazy(() =>
  import("./Components/Shared/My404Component/My404Component.jsx")
);
const Kids = React.lazy(() => import("./Components/Kids/Kids.jsx"));
const TasksHome = React.lazy(() => import("./Components/Tasks/TasksHome.jsx"));

const Container = React.lazy(() =>
  import("./Components/Shared/Container/Container")
);
const ForgotPassword = React.lazy(() =>
  import("./Components/ForgotPassword/ForgotPassword.jsx")
);
const ForgotPasswordConfirmation = React.lazy(() =>
  import(
    "./Components/ForgotPasswordConfirmation/ForgotPasswordConfirmation.jsx"
  )
);
const NewPassword = React.lazy(() =>
  import("./Components/NewPassword/NewPassword.jsx")
);
const Signup = React.lazy(() => import("./Components/Signup/Signup.jsx"));

const GiftList = React.lazy(() => import("./Components/Gifts/Gift.jsx"));

const Targets = React.lazy(() => import("./Components/Targets/Targets.jsx"));

function App() {
  const { t } = useTranslation();
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Login path="/" />
        <Login path="/login" />
        <ForgotPassword path="/forgot-password" />
        <ForgotPasswordConfirmation path="/forgot-password-confirmation" />
        <ConfirmEmail path="/confirm-email" />
        <RequestLanding path="/gift-landing" />
        <NewPassword path="/change-password/:Params" />
        <LoginWithDevice path="/kid-device/:DeviceId" />
        <LoadingLanding path="/gift-third/:KidId/:KidName/:Motive/:Character/:Message" />
        <LoadingLanding path="/gift-third/:`KidId/:KidName/:Motive/:Character/:Message/:ParentName/:Identification/:Bank/:BankNumber" />
        <Processing path="/processing-gift-third" />
        <Dashboard path="/dashboard">
          <Container
            title={t("menuAdmin.myKids")}
            hasOuterButton={true}
            toNavigate="new-kids"
            buttonTitle={t("menuAdmin.addKid")}
            path="/"
            mainImage="/kids.png"
            pageName='kids-page'
          >
            <Kids path="/">
              <SignupKids path="new-kids" />
              <PairKids path="pair-kids" />
              <WithdrawKids path="pending-withdraw" />
              <KidAddedPaired path="pairing-success" />
            </Kids>
          </Container>
          <Container
            title={t("menuAdmin.kidDetail")}
            hasOuterButton={false}
            toNavigate="dashboard/details-kids"
            buttonTitle={t("menuAdmin.kidDetail")}
            path="details-kids"
            hasBackButton={true}
          >
            <KidDetails path="/">
              <EditKids path="edit-kids" />
              <DisableKids path="disable-kids" />
            </KidDetails>
          </Container>
          <Container
            title={t("menuAdmin.myProfile")}
            hasOuterButton={false}
            toNavigate="new-kids"
            buttonTitle={t("menuAdmin.myKids")}
            path="/profile"
            hasBackButton={true}
            mainImage=""
          >
            <Profile path="/">
              <EnableKids path="enable-kids" />
              <UnsubscribeKids path="unsubscribe-kid" />
            </Profile>
          </Container>
          <Container
            title={t("menuAdmin.tasks")}
            hasOuterButton={true}
            toNavigate="new"
            buttonTitle={t("menuAdmin.createTask")}
            path="/tasks"
            mainImage="/tasksmenu.png"
            pageName="task-page"
          >
            <TasksHome path="/">
              <TaskNew path="new" />
              <TaskEdit path="edit" />
              <TaskDelete path="delete" />
              <TaskApprove path="approve" />
              <TaskReject path="reject" />
            </TasksHome>
          </Container>
          <Container
            title={t("menuAdmin.gifts")}
            hasOuterButton={false}
            toNavigate="new"
            buttonTitle={t("menuAdmin.createGift")}
            path="/gifts"
            mainImage="/savingsmenu.png"
          >
            <GiftList path="/">
              <ExternalGift path="third" />
              <InternalGift path="personal" />
              <ApproveNotApprove path="approve-not-approve" />
              <VerifyParent path="verify-parent" />
            </GiftList>
          </Container>
          <Container
            title={t("menuAdmin.giftThird")}
            hasOuterButton={false}
            toNavigate="new-kids"
            buttonTitle={t("menuAdmin.addKid")}
            path="/request-gift-link"
            hasBackButton={false}
            mainImage=""
          >
            <GiftLinks path="/" />
          </Container>
          <Container
            title={t("menuAdmin.targets")}
            hasOuterButton={true}
            toNavigate="new"
            buttonTitle={t("menuAdmin.createTarget")}
            path="/targets"
            mainImage="/targets.png"
          >
            <Targets path="/">
              <ApproveReject path="approve-reject" />
            </Targets>
          </Container>
          <Container
            title={""}
            hasOuterButton={false}
            toNavigate=""
            buttonTitle={""}
            path="/notifications"
            mainImage="/notifications.png"
          >
            <Notifications path="/"></Notifications>
          </Container>
          <Container
            title={t("termsAndConditions.title")}
            hasOuterButton={false}
            toNavigate=""
            buttonTitle={t("menuAdmin.createGift")}
            path="/terms-and-conditions"
            mainImage=""
          >
            <TermsAndConditions path="/"></TermsAndConditions>
          </Container>
        </Dashboard>
        <Signup path="/sign-up" />
        <SignupInfo path="/sign-up/more-info" />
        <Confirmation path="/confirmation/:id" />
        <InitialLoading path="/loading" />
        <My404Component path="/404" default />
      </Router>
    </Suspense>
  );
}

export default App;
