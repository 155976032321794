import { combineReducers } from "redux";

import AuthReducer from "./Auth/AuthReducer";
import KidsReducer from "./Kids/KidsReducer";
import TasksReducer from "./Tasks/TasksReducer";
import BanksReducer from "./Banks/BanksReducer";
import GiftsReducer from "./Gifts/GiftsReducer";
import TargetsReducer from "./Targets/TargetsReducer";
import NotificationsReducer from "./Notifications/NotificationsReducer";
import TutorialsReducer from "./Tutorials/TutorialsReducer";
import NewsReducer from "./News/NewsReducer";

export default combineReducers({
  auth: AuthReducer,
  kids: KidsReducer,
  tasks: TasksReducer,
  banks: BanksReducer,
  gifts: GiftsReducer,
  targets: TargetsReducer,
  notifications: NotificationsReducer,
  tips: TutorialsReducer,
  news: NewsReducer,
});
