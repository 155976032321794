import types from "./../types";
import update from 'immutability-helper';

const INITIAL_STATE = {
  kids: [],
  selectedKid: null,
  loading: false,
  error: "",
  message: "",
  kidDeviceId: "",
  kidsDisabled: [],
  lastKidIdAdded: null,
  piggies: [],
  pendingWithdrawals: [],
  withdrawals: [],
  transactions: {},
  pair: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REGISTER_KIDS:
      return { ...state, loading: action.payload, error: "" };
    case types.FETCH_KIDS_SUCCESS:
      const kidsEnable = action.payload
        .filter((kid) => !!kid.Status && !kid.IsUnsubscribe)
        .reverse();
      const kidsDisable = action.payload
        .filter((kid) => !kid.Status && !kid.IsUnsubscribe)
        .reverse();
      return {
        ...state,
        kids: kidsEnable,
        kidsDisabled: kidsDisable,
        pair: true,
        loading: false,
      };
    case types.UNSUBSCRIBE_KIDS_SUCCESS:
      const kidsDisableFiltered = state.kidsDisabled.filter(
        (kid) => kid.Id !== action.payload
      );
      return { ...state, kidsDisabled: kidsDisableFiltered };
    case types.SELECTED_KID:
      return { ...state, selectedKid: action.payload };
    case types.PAIR_KIDS_SUCCESS:
      return { ...state, kidDeviceId: null, pair: false };
    case types.CLEAN_CHILDS_IDS_SUCCESS:
      return { ...state, lastKidIdAdded: null };
    case types.USER_KID_DEVICEID_SUCCESS:
      return { ...state, kidDeviceId: action.payload, pair: true };
    case types.FETCH_KIDS_PIGGIES_SUCCESS:
      const enabled = action.payload
        .filter((kid) => !!kid.Child.Status)
        .reverse();
      return { ...state, piggies: enabled };
    case types.FETCH_PIGGY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: { [state.selectedKid.Id]: action.payload },
      };
    case types.FETCH_KIDS_PENDING_WITHDRAWALS_SUCCESS:
      const findKidIsActive = (id) => state.kids.find((item) => item.Id === id);
      const pendingWithdraw =
        action.payload &&
        action.payload
          .filter((item) => {
            const found = findKidIsActive(item.ChildId);
            if (
              item.StatusCode === "WITHDRAWALPENDING" &&
              found &&
              found.Status
            ) {
              return item;
            }
          })
          .reverse();
      return {
        ...state,
        pendingWithdrawals: pendingWithdraw,
        withdrawals: action.payload,
      };
    case types.REGISTER_KID_SUCCESS:
      return {
        ...state,
        kidDeviceId: null,
        pair: false,
        lastKidIdAdded: action.payload,
      };
    case types.LOGOUT_USER_SUCCESS:
      const kidDevice = state.kidDeviceId;
      return { ...INITIAL_STATE, kidDeviceId: kidDevice };
    case types.REMOVE_KID_PHOTO:
      const selectedKid = state.selectedKid;
      selectedKid.Child.Photo = ' ';
      return update(state, { selectedKid: { $set: selectedKid } });
    default:
      return state;
  }
};
