import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import styles from "./gift-link.module.scss";
export default function GiftLinks() {
  const { t } = useTranslation();
  const thirdGiftLink = useSelector((state) => state.gifts.thirdGiftLink);
  const copyClipboard = async function copyPageUrl() {
    const text = `https://${document.getElementById('myLink').value}`;
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`${t('success.giftLinkCopied')}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("Page URL copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const shareLink = async (e) => {
    if (navigator && navigator.share) {
      copyClipboard(`https://${thirdGiftLink?.link}`);
      const response = await navigator
        .share({
          title: t("giftLinks.shareTitle"),
          text: `${t("giftLinks.shareHelp")} ${thirdGiftLink?.kid?.FirstName} ${
            thirdGiftLink?.kid?.LastName
          } ${t("giftLinks.shareGift")}`,
          url: `https://${thirdGiftLink?.link}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.log(`Couldn't share because of`, err.message);
        });
    } else {
      console.log("web share not supported");
      copyClipboard(`https://${thirdGiftLink?.link}`);
    }
  };
  return (
    <div className={styles.mainContent}>
      <p className={styles.linkRequestSuccess}>{t("giftLinks.title")}</p>
      <p className={styles.linkInfo}>
        {t("giftLinks.shareTitle")} {t("giftLinks.shareHelp")}{" "}
        {thirdGiftLink?.kid?.FirstName} {thirdGiftLink?.kid?.LastName}{" "}
        {t("giftLinks.shareGift")}
      </p>
      <div className={styles.linkContainer}>
        <div className={styles.link}>
          <img
            className={styles.linkImage}
            src="/linkImage.png"
            alt="Clinkky Link"
          />
          <button onClick={(e) => shareLink(e)} className={styles.linkText}>
            <input
              className={styles.linkInput}
              id="myLink"
              type="text"
              disabled
              defaultValue={thirdGiftLink?.link}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
