/* eslint-disable import/no-anonymous-default-export */
import types from "./../types";

const INITIAL_STATE = {
  gifts: [],
  giftsThirdPending: [],
  giftsAllDone: [],
  giftsAllRejected: [],
  lastGiftAddedId: null,
  loading: false,
  error: '',
  message: '',
  thirdGiftSelected: null,
  thirdGiftLink: null,
  thirdGiftUrl: null,
  thirdGiftUser: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOADING_GIFTS:
      return { ...state, loading: action.payload, error: '' };
    case types.ADD_GIFTS:
      return {
        ...state,
        lastGiftAddedId: action.payload.Motive,
        error: '',
      };
    case types.FETCH_GIFTS_SUCCESS:
      const findKidIsActive = (id) =>
        action.payload.Kids.find((item) => item.Id === id);
      const thirdPending = action.payload.Gifts.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (
          item.StatusCode === 'GIFTPENDING' &&
          !item.IsInternal &&
          found &&
          found.Status
        ) {
          return item;
        }
      }).reverse();
      const allDone = action.payload.Gifts.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (
          item.StatusCode !== 'GIFTPENDING' &&
          item.StatusCode !== 'GIFTREJECTED' &&
          found &&
          found.Status
        ) {
          return item;
        }
      }).reverse();
      const allRejected = action.payload.Gifts.filter((item) => {
        const found = findKidIsActive(item.ChildId);
        if (item.StatusCode === 'GIFTREJECTED' && found && found.Status) {
          return item;
        }
      }).reverse();
      return {
        ...state,
        gifts: action.payload.Gifts,
        giftsThirdPending: thirdPending,
        giftsAllDone: allDone,
        giftsAllRejected: allRejected,
        loading: false,
      };
    case types.CLEAN_GIFTS_IDS_SUCCESS:
      return { ...state, lastGiftAddedId: null };
    case types.THIRD_SELECTED_GIFTS_SUCCESS:
      return { ...state, thirdGiftSelected: action.payload };
    case types.THIRD_GIFTS_LINK:
      return { ...state, thirdGiftLink: action.payload };
    case types.THIRD_GIFTS_URLS:
      return { ...state, thirdGiftUrl: action.payload };
    case types.THIRD_GIFTS_USER:
      return { ...state, thirdGiftUser: action.payload };
    case types.LOGOUT_USER_SUCCESS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
