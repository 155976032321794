function useBase64() {
  return (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      if (file) reader.readAsDataURL(file);
      reader.onload = async () => {
        resolve(reader.result);
      };
      reader.onerror = async (error) => {
        reject(error);
      };
    });
  };
}

export default useBase64;
