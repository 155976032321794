import React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "@reach/router";
import { Link } from "@reach/router";
import Cleave from "cleave.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Input from "./../../../Shared/Input/Input.jsx";
import Select from "./../../../Shared/Select/Select.jsx";

function TasksForm({ styles, loading, onSubmit, values, kids }) {
  const [firstTime, setFirstTime] = React.useState(false);
  const [frequency, setFrequency] = React.useState("");
  const [kidSelected, setKidSelected] = React.useState("");
  const [dayMonth, setDayMonth] = React.useState("");
  const [weekMap, setWeekMap] = React.useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });
  const { t } = useTranslation();
  const selectedTask = useSelector((state) => state.tasks.selectedTask.task);
  const rawTasks = useSelector((state) => state.tasks.rawTasks);
  const placeholders = {
    title: t('forms.title'),
    toWhom: t('forms.toWhom'),
    amount: t('forms.amount'),
    frequency: t('forms.frequency'),
    date: t('forms.date'),
    dayMonth: t('forms.dayMonth'),
    time: t('forms.time'),
  };
  const frecuencyObj = {
    once: t("forms.once"),
    weekly: t("forms.weekly"),
    monthly: t("forms.monthly"),
  };
  const location = useLocation();
  const locationsToShow = ["/dashboard/tasks/edit"];
  const shouldDisplayButton = locationsToShow.includes(location.pathname);
  const currentRawTask =
    shouldDisplayButton &&
    rawTasks &&
    rawTasks.find((item) => values.TaskId === item.Id);
  const momentFormat = "Y/`m/`d";
  const { register, handleSubmit, setValue, errors } = useForm();
  const now = moment(new Date()).format("YYYY-MM-DD").toString();
  React.useEffect(() => {
    let amountCleave = new Cleave("#Amount", {
      numeral: true,
      delimiter: "",
      numeralThousandsGroupStyle: "thousand",
    });
  }, []);
  React.useEffect(() => {
    let timeOut;
    if (frequency === "monthly") {
      timeOut = setTimeout(() => {
        let dateMontlyCleave = new Cleave("#DateMonthly", {
          prefix: "Día",
          date: true,
          delimiter: "-",
          datePattern: ["d"],
          uppercase: true,
        });
      }, 200);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [frequency]);
  React.useEffect(() => {
    let timeOut = setTimeout(() => {
      if (
        shouldDisplayButton &&
        values &&
        values.Id &&
        kids &&
        kids.length > 0
      ) {
        const findSelected = kids.find((item) => item.Id === values.ChildId);
        if (!firstTime) {
          setValue(
            "KidSelected",
            `${findSelected.FirstName} ${findSelected.LastName}`
          );
          setFirstTime(true);
        } else {
          setKidSelected(kidSelected);
        }
        clearTimeout(timeOut);
      }
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, [values, kids, kidSelected]);
  React.useEffect(() => {
    if (
      shouldDisplayButton &&
      selectedTask &&
      selectedTask.Schedule &&
      selectedTask.Schedule.Type === "weekly"
    ) {
      const dataArr = selectedTask.Schedule.Data.split(",");
      let weekValuesMap = { ...weekMap };
      dataArr.forEach((item) => {
        const copy = { ...weekValuesMap };
        switch (item) {
          case "SUN":
            weekValuesMap = { ...copy, sunday: true };
            break;
          case "MON":
            weekValuesMap = { ...copy, monday: true };
            break;
          case "TUE":
            weekValuesMap = { ...copy, tuesday: true };
            break;
          case "WED":
            weekValuesMap = { ...copy, wednesday: true };
            break;
          case "THU":
            weekValuesMap = { ...copy, thursday: true };
            break;
          case "FRI":
            weekValuesMap = { ...copy, friday: true };
            break;
          case "SAT":
            weekValuesMap = { ...copy, saturday: true };
            break;
        }
      });

      setWeekMap({ ...weekMap, ...weekValuesMap });
    }
    let timeOut = setTimeout(() => {
      if (
        values &&
        values.Id &&
        selectedTask &&
        selectedTask.Schedule &&
        selectedTask.Schedule.Type
      ) {
        setValue(
          "Frequency",
          selectedTask.Schedule.Type.charAt(0).toUpperCase() +
            selectedTask.Schedule.Type.slice(1)
        );
        setFrequency(
          selectedTask.Schedule.Type.charAt(0).toUpperCase() +
            selectedTask.Schedule.Type.slice(1)
        );
        if (selectedTask.Schedule.Type === "monthly") {
          setValue("DateMonthly", selectedTask.Schedule.Data);
        }
        clearTimeout(timeOut);
      }
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  const keys = Object.keys(weekMap);
  const renderFrequency = (freq) => {
    const arrKeys = Object.keys(frecuencyObj);
    const newFreq = arrKeys.find((item) => frecuencyObj[item] === freq);
    switch (newFreq) {
      case "once":
        return <></>;
      case "weekly":
        return (
          <React.Fragment>
            <div className={styles.daysContainer}>
              {keys.map((item) => (
                <div
                  key={item}
                  className={[weekMap[item] ? styles.dayOn : styles.dayOff]}
                  onClick={() => {
                    setWeekMap({ ...weekMap, [item]: !weekMap[item] });
                  }}
                >
                  {t(`weekDays.${item}`).charAt(0)}
                </div>
              ))}
            </div>
          </React.Fragment>
        );
      case "monthly":
        return (
          <React.Fragment>
            <div className={styles.input}>
              <label className={styles.birthDate}>
                <input
                  type='text'
                  id='DateMonthly'
                  name='DateMonthly'
                  ref={register({ required: true })}
                  aria-invalid='true'
                  required
                  aria-required='true'
                  aria-describedby='day_error'
                  aria-label={placeholders.dayMonth}
                  placeholder={placeholders.dayMonth}
                  data-placeholder={placeholders.dayMonth}
                  value={dayMonth}
                  onChange={(e) => {
                    if (e.target.value > 0 && e.target.value < 32 || e.target.value === '') {
                      setDayMonth(e.target.value);
                    }
                  }}
                />
              </label>
              <br />
              {errors.Date && (
                <span id='day_error' role='alert'>
                  {t('forms.day')} {t('forms.isRequired')}
                </span>
              )}
            </div>
          </React.Fragment>
        );
      default:
        return <></>;
    }
  };
  const composeSubmit = (extra) => {
    const { Frequency, DateMonthly } = extra;
    const currentFe = () => {
      const now = moment().utc();
      const nowDate = new Date(now);
      const arrKeys = Object.keys(frecuencyObj);
      const newFreq = arrKeys.find((item) => frecuencyObj[item] === Frequency);
      switch (newFreq) {
        case "once":
          return { type: "once", frequency: "" };
        case "weekly":
          const getDays = keys
            .filter((item) => !!weekMap[item])
            .map((item) => item.slice(0, 3).toUpperCase());
          return { type: "weekly", frequency: getDays };
        case "monthly":
          return { type: "monthly", frequency: DateMonthly };
      }
    };

    onSubmit({ ...extra, currentFrequency: currentFe() });
  };
  return (
    <form id="form-add-task" className={styles.form} onSubmit={handleSubmit(composeSubmit)}>
      <Input
        styles={styles.input}
        register={register}
        isRequired={true}
        errors={errors}
        type="text"
        name="Description"
        value={values && values?.Description ? values.Description : ""}
        placeholder={placeholders.title}
      />
      <Select
        styles={styles.input}
        register={register}
        isRequired={true}
        errors={errors}
        type="text"
        name="KidSelected"
        placeholder={placeholders.toWhom}
        firstOption={placeholders.toWhom}
        optionsValues={kids}
        typeOptions="person"
        setFunction={setKidSelected}
      />
      <Input
        styles={styles.input}
        register={register}
        isRequired={true}
        errors={errors}
        type="text"
        value={values && values?.Amount ? values.Amount : ""}
        name="Amount"
        placeholder={placeholders.amount}
      />
      <Select
        styles={styles.input}
        register={register}
        isRequired={true}
        errors={errors}
        type="text"
        name="Frequency"
        placeholder={placeholders.frequency}
        firstOption={placeholders.frequency}
        optionsValues={frecuencyObj}
        typeOptions="object"
        repeatRequired={true}
        setFunction={setFrequency}
      />
      {frequency && renderFrequency(frequency)}
      <button
        type="submit"
        className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
      >
        {shouldDisplayButton ? t("forms.edit") : t("forms.create")}
      </button>
      <Link className={styles.goBack} to="/dashboard/tasks">
        {t("forms.goback")}
      </Link>
    </form>
  );
}

export default React.memo(TasksForm);
