import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import Cleave from "cleave.js";
import moment from "moment";

import Input from "./../../../Shared/Input/Input.jsx";
import Select from "./../../../Shared/Select/Select.jsx";
import Autocomplete from "react-autocomplete";
import autocomplete from "./../FormExternal/form-external.module.scss";
import internal from './../../../Shared/Input/input.module.scss';

function FormInternal({ styles, loading, onSubmit, values, kids, options }) {
  const { t } = useTranslation();
  const [motiveSelected, setMotiveSelected] = React.useState("");
  const [characterSelected, setCharacterSelected] = React.useState("");
  const [kidSelected, setKidSelected] = React.useState("");
  const { register, handleSubmit, errors } = useForm();
  const now = moment(new Date()).format("YYYY-MM-DD").toString();
  React.useEffect(() => {
    let amountCleave = new Cleave("#Amount", {
      numeral: true,
      delimiter: "",
      numeralThousandsGroupStyle: "thousand",
    });
  }, []);
  React.useEffect(() => {
    return () => {};
  }, []);

  const placeholders = {
    toWhom: t("forms.toWhom"),
    motive: t("forms.motive"),
    character: t("forms.magicalCharacters"),
    amount: t("forms.amount"),
  };
  const optionsValuesCharacters = t("magicCharacters", { returnObjects: true });

  const composeSubmit = (extra) => {
    const { KidSelected, Motive, Amount } = extra;
    extra.Motive = motiveSelected;
    extra.Character = characterSelected;
    onSubmit({ ...extra });
  };
  return (
    <form id="form-personal-gift" className={styles.form} onSubmit={handleSubmit(composeSubmit)}>
      <Select
        styles={styles.input}
        register={register}
        isRequired={true}
        errors={errors}
        type="text"
        name="KidSelected"
        placeholder={placeholders.toWhom}
        firstOption={placeholders.toWhom}
        optionsValues={kids}
        typeOptions="person"
        setFunction={setKidSelected}
      />
      <Autocomplete
        getItemValue={(item) => item.label}
        items={options}
        shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
        renderMenu={(items, value, styles) => {
          return items.length > 0 ? <div className={autocomplete.container} children={items}/> : <div />
        }}
        renderItem={(item, isHighlighted) =>
          <div key={item.label} className={autocomplete.item} style={{ background: isHighlighted ? 'lightgray' : 'transparent' }}>
            {item.label}
          </div>
        }
        wrapperProps={{ className: styles.input, style: { display: 'block', position: 'relative' } }}
        value={motiveSelected}
        name="Motive"
        onChange={(e) => setMotiveSelected(e.target.value)}
        onSelect={(val) => setMotiveSelected(val)}
        renderInput={(props) => {
          return (
            <>
              <input placeholder={placeholders.motive} {...props}  />
              <img className={internal.inputImage} src="/suggestEdit.png" />
            </>
          )
        }}
      />
      <Autocomplete
        getItemValue={(item) => item.label}
        items={optionsValuesCharacters}
        shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
        renderMenu={(items, value, styles) => {
          return items.length > 0 ? <div className={autocomplete.container} children={items}/> : <div />
        }}
        renderItem={(item, isHighlighted) =>
          <div key={item.label} className={autocomplete.item} style={{ background: isHighlighted ? 'lightgray' : 'transparent' }}>
            {item.label}
          </div>
        }
        wrapperProps={{ className: styles.input, style: { display: 'block', position: 'relative' } }}
        value={characterSelected}
        name="Character"
        onChange={(e) => setCharacterSelected(e.target.value)}
        onSelect={(val) => setCharacterSelected(val)}
        renderInput={(props) => {
          return (
            <>
              <input placeholder={placeholders.character} {...props} />
              <img className={internal.inputImage} src='/suggestEdit.png' />
            </>
          );
        }}
      />
      <Input
        styles={styles.input}
        register={register}
        isRequired={true}
        errors={errors}
        type="text"
        value={values && values?.Amount ? values.Amount : ""}
        name="Amount"
        placeholder={placeholders.amount}
      />

      <button
        type="submit"
        className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
      >
        {t("forms.doGift")}
      </button>
      <Link className={styles.goBack} to="../">
        {t("forms.goback")}
      </Link>
    </form>
  );
}
export default React.memo(FormInternal);
