import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers/index.js";
import { ToastContainer } from "react-toastify";
import "./../node_modules/react-toastify/dist/ReactToastify.css";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import { PersistGate } from "redux-persist/integration/react";
import "./i18next.js";
import Loading from "./Components/Shared/Loading/Loading";

// Sentry.init({
//   dsn:
//     "https://da467e6b162a4b1b87b0390e0f3de98f@o421397.ingest.sentry.io/5341098",
// });

ReactGA.initialize("UA-176935738-2");

const persistConfig = {
  key: "root",
  storage: storage("myDB"),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
