import React from "react";
import { useTranslation } from "react-i18next";

import HeaderModal from "./../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./kidaddedpaired.module.scss";

export default function KidAddedPaired({ navigate }) {
  const { t } = useTranslation();

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t("kidAddedPaired.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="red"
        />
        <div className={styles.mainContent}>
          <div className={styles.row}>
            <p>{t("kidAddedPaired.description")}</p>
          </div>
          <div className={styles.row}>
            <br />
          </div>
          <div className={styles.row}>
            <p className={styles.subClass}>
              {t("kidAddedPaired.descriptionTwo")}
            </p>
          </div>
          <div className={styles.row}>
            <br />
          </div>
          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                navigate("/dashboard");
              }}
              type="button"
              className={[styles.btnLogin]}
            >
              {t("kidAddedPaired.done")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
