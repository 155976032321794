import React from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import internal from "./date.module.scss";

const minDate = moment(new Date()).subtract(18, 'years').toString()

const zeroZone = (value) => {
  let timeZero = new Date(value);
  timeZero.setMinutes(timeZero.getMinutes()+timeZero.getTimezoneOffset());
  return timeZero;
};

const Dates = ({
  styles,
  register,
  isRequired,
  value,
  name,
  placeholder,
  errors,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = React.useState(value ? new Date(zeroZone(value)) : new Date());

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <label className={internal.birthDate}>
      <input
        ref={register({ required: isRequired })}
        id={name}
        className={internal.calendarInput}
        name={name}
        defaultValue={value}
        aria-invalid='true'
        required
        aria-required='true'
        aria-invalid={errors && errors[name] ? true : false}
        aria-describedby={`${placeholder}_error`}
        aria-labelledby={`${placeholder}_error`}
        aria-label={placeholder}
        placeholder={placeholder}
        data-placeholder={placeholder}
        onClick={onClick}
      />
      <img src='/calendar.png' alt='Birthday calendar' />
    </label>
  ));

  return (
    <div className={styles}>
      <DatePicker
        selected={startDate}
        minDate={new Date(minDate)}
        maxDate={new Date()}
        customInput={<ExampleCustomInput />}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        popperPlacement='top-start'
        onChange={(date) => setStartDate(date)}
      />
      <span
        className={[errors && errors[name] ? internal.show : internal.hide]}
        id={`${placeholder}_error`}
        role='alert'
      >
        {`${placeholder} ${t('forms.isRequired')}`}
      </span>
    </div>
  );
};

export default React.memo(Dates);
