import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./pair.module.scss";

const Pair = ({ navigate }) => {
  const { t } = useTranslation();
  const [activeKid, setActiveKid] = React.useState("");
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/kids/pair",
      method: "POST",
    },
    { manual: true }
  );
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const kidRedux = useSelector((state) => state.kids.kids);
  const kidDeviceId = useSelector((state) => state.kids.kidDeviceId);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async () => {
    if (!activeKid) {
      toast.error(`${t("errors.kidNoSelected")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const response = await executeRequest({
      data: {
        DeviceId: kidDeviceId,
        ChildId: activeKid,
        Cookie: cookie,
      },
    });
    if (response?.data?.OperationStatus === "Ok") {
      toast.success(`${t("success.pairDevice")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({
        type: types.PAIR_KIDS_SUCCESS,
        payload: response?.data,
      });
      navigate("/dashboard");
    } else {
      toast.error(`${"errors.pairDevice"}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t('pairKids.title')}
          backButton={false}
          closeButton={true}
          closeButtonColor='white'
        />
        <div className={styles.mainContent}>
          <p>{t('pairKids.subtitle')}</p>
          <div className={styles.kidsContainer}>
            {kidRedux &&
              kidRedux?.map((item) => (
                <div
                  key={item.Id}
                  onClick={() => setActiveKid(item?.Id)}
                  className={styles.kid}
                >
                  <img
                    className={
                      item?.Id === activeKid
                        ? styles.activeKid
                        : styles.kidImage
                    }
                    src='/kid_img_boy.png'
                    alt=''
                  />
                  <h4
                    className={
                      item?.Id === activeKid
                        ? styles.activeKidText
                        : styles.kidText
                    }
                  >
                    {item.FirstName} {item.LastName}
                  </h4>
                </div>
              ))}
          </div>
          <div className={styles.buttonsContainer}>
            <button
              type='button'
              onClick={() => onSubmit()}
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t('pairKids.pair')}
            </button>
            <button
              onClick={() => navigate('../new-kids')}
              type='button'
              className={[loading ? styles.btnLoginDisabled : styles.btnSecond]}
            >
              {t('pairKids.addNewKid')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pair;
