import React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { mutate } from "swr";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "./../../../reducers/types";
import Cookies from "js-cookie";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";
import FormExternal from "../Shared/FormExternal/FormExternal.jsx";
import Tutorials from "../../Tutorials/Tutorials.jsx";

import styles from "./external-gift.module.scss";

export default function ExternalGift({ navigate }) {
  const { t } = useTranslation();
  const [
    { data, loading, error },
    executeRequest,
  ] = useAxios(
    {
      url: config.urlServer + "/gifts/get-url-third",
      method: "POST",
    },
    { manual: true }
  );

  const optionsValues = t("motives", { returnObjects: true });

  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state.auth.user);
  const kidsRedux = useSelector((state) => state.kids.kids);
  const tutorialBank = useSelector((state) => state.tips.bank);
  const [bankInfo, setBankInfo] = React.useState(false);

  React.useEffect(() => {
    prefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  async function prefetch() {
    const banksData = await mutate(
      { url: config.urlServer + "/banks/", method: "POST" },
      fetch(config.urlServer + "/banks/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }).then((res) => res.json())
    );
    dispatch({
      type: types.FETCH_BANKS_SUCCESS,
      payload: banksData.Banks,
    });
  }
  
  const handleDataUrl = (dataUrl) => {
    if (dataUrl) return encodeURIComponent(dataUrl.split(" ").join("-"));
    return '';
  };

  const onSubmit = async (data) => {
    const base = window.location.origin;
    const selectedKid = kidsRedux.find((item) =>
      data.KidSelected.includes(item.FirstName)
    );
    if (selectedKid && selectedKid.Id) {
      const urlText =
        data && data.Character
          ? `${base}/gift-third/${selectedKid.Id}/${handleDataUrl(selectedKid?.FirstName)
            }/${handleDataUrl(data?.Motive)}/${handleDataUrl(data?.Character)}/${handleDataUrl(data?.Message)}`
          : `${base}/gift-third/${selectedKid.Id}/${handleDataUrl(selectedKid?.FirstName)
            }/${handleDataUrl(data?.Motive)}/${handleDataUrl(data?.Character)}/${handleDataUrl(data?.Message)}`;
      const response = await executeRequest({
        data: {
          UrlText: urlText,
          Cookie: cookie,
        },
      });
      dispatch({
        type: types.THIRD_GIFTS_LINK,
        payload: {
          kid: selectedKid,
          link: response?.data?.Url,
          motive: data.Motive,
          character: data.Character,
        },
      });
      navigate("/dashboard/request-gift-link");
    }
  };

  const handleTutorialBank = () => {
    setBankInfo(true);
  };

  return (
    <>
      {bankInfo && !tutorialBank?.some(tb => tb === userRedux?.Id) && <Tutorials />}
      <div className={styles.backDrop}>
        <div className={styles.container}>
          <HeaderModal
            navigate={navigate}
            title={t("giftExternal.title")}
            backButton={false}
            closeButton={true}
            closeButtonColor="white"
          />
          <FormExternal
            styles={styles}
            loading={loading}
            onSubmit={onSubmit}
            values=""
            kids={kidsRedux}
            options={optionsValues}
            handleTutorialBank={handleTutorialBank}
          />
        </div>
      </div>
    </>
  );
}
