import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "@reach/router";
import HeaderModal from "../Shared/HeaderModal/HeaderModal.jsx";
import styles from "./banksAlert.module.scss";

const BanksAlert = ({banksAlertClose}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddFinance = () => {
    navigate("/dashboard/profile");
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("giftRequestLanding.bank.title")}
          backButton={false}
          closeButton={false}
          closeButtonColor="white"
          financeClose={banksAlertClose}
        />
        <div className={styles.tutorialMessage}>
          {t("giftRequestLanding.bank.problem")}
        </div>
        <button onClick={handleAddFinance} className={styles.btnAddBank}>
          {t("giftRequestLanding.bank.button")}
        </button>
      </div>
    </div>
  );
};

export default BanksAlert;
