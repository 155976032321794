import * as React from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { useTranslation } from "react-i18next";
import types from "./../../../reducers/types";
import stylesLogos from "./../../styles-general/logos.module.scss";
import styles from "./loading-landing.module.scss";

const LoadingLanding = ({ navigate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { KidId, KidName, Motive, Message, ParentName, Identification, Bank, BankNumber } = useParams();
  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    callConfirmation();
  }, []);

  const callConfirmation = async () => {
    dispatch({
      type: types.THIRD_GIFTS_URLS,
      payload: { KidId, KidName, Motive, Message, ParentName, Identification, Bank, BankNumber },
    });
    navigate("/gift-landing");
  };
  return (
    <div>
      <div className={styles.header}>
        <img
          src="/white-logo.png"
          className={stylesLogos.img}
          alt="Clinkky Admin"
        />
      </div>
      <div className={styles.loginContainer}>
        <form className={styles.loginForm}>
          <h1 className={styles.title}>{t("giftLoadingLanding.title")}</h1>
          <div className={styles.chanchaContainer}>
            <img className={styles.chancha} src="/clinkky_sin_ocico.png" />
            <img
              className={styles.chanchaHocico}
              src="/hocico.png"
              alt={t("giftLoadingLanding.subTitle")}
            />
          </div>
          <p>{t("giftLoadingLanding.subTitle")}</p>
        </form>
      </div>
      <footer className={styles.footer}>
        <p className={styles.copyRight}>
          CLINKKY © {new Date().getFullYear()} - All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default LoadingLanding;
