import types from "./../types";

const INITIAL_STATE = {
  token: null,
  register: null,
  loading: false,
  error: "",
  message: "",
  user: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET_AUTH:
      return { ...state, ...INITIAL_STATE };
    case types.LOADING_AUTH:
      return { ...state, loading: action.payload };
    case types.LOGIN_USER:
      return { ...state, loading: true, errorLogin: '' };
    case types.LOGIN_USER_SUCCESS:
      return { ...state, user: { ...action.payload } };
    case types.UPDATE_USER_SUCCESS:
      return { ...state, user: { ...action.payload } };
    case types.FORGOTPASSWORD_USER:
      return { ...state };
    case types.FORGOTPASSWORD_USER_SUCCESS:
      return { ...state };
    case types.REGISTER_USER:
      return { ...state, register: action.payload };
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        register: null,
        user: action.payload,
      };
    case types.REMOVE_PROFILE_PHOTO:
      if (state.user) return { ...state, user: { ...state.user, Photo: ' ' } };
      return { ...state };
    case types.LOGOUT_USER:
      return { ...INITIAL_STATE };
    case types.LOGOUT_USER_SUCCESS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
