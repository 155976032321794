import React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import types from "./../../../reducers/types.js";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";
import FormInternal from "../Shared/FormInternal/FormInternal.jsx";

import styles from "./internal-gift.module.scss";

export default function InternalGift({ navigate }) {
  const { t } = useTranslation();
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: config.urlServer + "/gifts/create-gift-parent",
      method: "POST",
    },
    { manual: true }
  );

  const optionsValues = t("motives", { returnObjects: true });
  const dispatch = useDispatch();
  const cookie = Cookies.get("user");
  const kidsRedux = useSelector((state) => state.kids.kids);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async (data) => {
    const selectedKid = kidsRedux.find((item) =>
      data.KidSelected.includes(item.FirstName)
    );
    if (!selectedKid) {
      toast.error(`No kids...`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const rawGift = {
      ChildId: selectedKid.Id,
      Amount: parseInt(data.Amount),
      Motive: `${data.Motive} ${data && data.Character ? "-" : ""} ${
        data.Character
      }`,
      Message: data.Message,
    };
    const response = await executeRequest({
      data: {
        Gift: { ...rawGift },
        Cookie: cookie,
      },
    });

    dispatch({
      type: types.ADD_GIFTS,
      payload: rawGift,
    });

    navigate("/dashboard/gifts");
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("giftInternal.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="white"
        />
        <FormInternal
          styles={styles}
          loading={loading}
          onSubmit={onSubmit}
          values=""
          kids={kidsRedux}
          options={optionsValues}
        />
      </div>
    </div>
  );
}
