import React from "react";
import config from "../../../config/config";
import { useForm } from "react-hook-form";
import useAxios from "axios-hooks";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./approve-not-approve.module.scss";

export default function ApproveNotApprove({ navigate }) {
  const { t } = useTranslation();
  const [noApproveMessage, setNoApproveMessage] = React.useState("");
  const { register, handleSubmit, errors } = useForm();
  const [noApprove, setNoApprove] = React.useState(false);
  const [btnApprove, setBtnApprove] = React.useState(false);
  const userRedux = useSelector((state) => state.auth.user);
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: config.urlServer + "/gifts/approve-gift",
      method: "POST",
    },
    { manual: true }
  );
  const [
    { data: dataReject, loading: loadingReject, error: errorReject },
    executeRequestReject,
  ] = useAxios(
    {
      url: config.urlServer + "/gifts/reject-gift",
      method: "POST",
    },
    { manual: true }
  );

  const cookie = Cookies.get("user");
  const thirdGiftSelected = useSelector(
    (state) => state.gifts.thirdGiftSelected
  );

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async (data) => {
    setBtnApprove(true);
    let response;
    if (data === "approve") {
      response = await executeRequest({
        data: {
          Id: thirdGiftSelected.gift.Id,
          Message: noApproveMessage,
          Cookie: cookie,
        },
      });
    }
    if (data === "reject") {
      response = await executeRequestReject({
        data: {
          Id: thirdGiftSelected.gift.Id,
          Message: noApproveMessage,
          Cookie: cookie,
        },
      });
    }

    navigate("/dashboard/gifts");
    window.location.reload();
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={
            thirdGiftSelected &&
            thirdGiftSelected.gift &&
            thirdGiftSelected.gift.IsInternal
              ? `${t('giftInternal.title')}`
              : t('giftApproveNoApprove.title')
          }
          backButton={false}
          closeButton={true}
          closeButtonColor='white'
        />
        <div className={styles.mainContent}>
          <div className={styles.rows}>
            <label htmlFor=''>{t('giftApproveNoApprove.from')}:</label>
            <p>
              {thirdGiftSelected &&
              thirdGiftSelected.gift &&
              thirdGiftSelected.gift.IsInternal
                ? `${
                    userRedux.FirstName
                      ? `${userRedux.FirstName} ${userRedux.LastName}`
                      : t('giftApproveNoApprove.you')
                  }`
                : `${thirdGiftSelected.gift.Donor.FirstName} ${thirdGiftSelected.gift.Donor.LastName}`}
            </p>
          </div>
          {thirdGiftSelected?.gift?.Motive?.split('-')[1] && (
            <div className={styles.rows}>
              <label htmlFor=''>{t('giftApproveNoApprove.character')}:</label>
              <p>{thirdGiftSelected?.gift?.Motive?.split('-')[1]}</p>
            </div>
          )}
          {thirdGiftSelected?.gift?.Motive?.split('-')[0] && (
            <div className={styles.rows}>
              <label htmlFor=''>{t('giftApproveNoApprove.motive')}:</label>
              <p>{thirdGiftSelected?.gift?.Motive?.split('-')[0]}</p>
            </div>
          )}
          <div className={styles.rows}>
            <label htmlFor=''>{t('giftApproveNoApprove.to')}:</label>
            <p>
              {thirdGiftSelected?.kid?.FirstName}{' '}
              {thirdGiftSelected?.kid?.LastName}
            </p>
          </div>
          <div className={styles.rows}>
            <label htmlFor=''>{t('giftApproveNoApprove.amount')}:</label>
            <p>{thirdGiftSelected.gift.Amount}</p>
          </div>
          {noApprove && (
            <div className={styles.rows}>
              <label htmlFor=''>{t('giftApproveNoApprove.whyNoApprove')}</label>
              <textarea
                className={styles.input}
                ref={register({ required: true })}
                name='Message'
                id='Message'
                cols='30'
                rows='10'
                value={noApproveMessage}
                onChange={(e) => setNoApproveMessage(e.target.value)}
                placeholder={`${t('giftApproveNoApprove.writeTo')} ${
                  thirdGiftSelected.gift.Donor.FirstName
                }`}
              ></textarea>
            </div>
          )}
          {thirdGiftSelected &&
            thirdGiftSelected.gift &&
            thirdGiftSelected.gift.StatusCode === 'GIFTPENDING' && (
              <div className={styles.orderButtons}>
                <div
                  className={
                    noApprove
                      ? `${styles.buttonContainer} ${styles.orderTwo}`
                      : `${styles.buttonContainer} ${styles.orderOne}`
                  }
                >
                  <button
                    onClick={() => onSubmit('approve')}
                    type='button'
                    disabled={btnApprove}
                    className={[ loading ? styles.btnLoginDisabled : styles.btnLogin ]}
                  >
                    {t('forms.approve')}
                  </button>
                </div>
                <br />
                <div
                  className={
                    noApprove
                      ? `${styles.buttonContainer} ${styles.orderOne}`
                      : `${styles.buttonContainer} ${styles.orderTwo}`
                  }
                >
                  <button
                    onClick={() => onSubmit('reject')}
                    type='button'
                    disabled={btnApprove}
                    className={[ loadingReject ? styles.btnLoginDisabled : styles.btnSecond ]}
                  >
                    {t('forms.noApprove')}
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
