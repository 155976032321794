import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import types from "../../reducers/types";
import styles from "./row-notifications.module.scss";

function RowNotifications({ navigate, notification, markAsRead }) {
  const [relatedKid, setRelatedKid] = React.useState("");
  const [relatedInfo, setRelatedInfo] = React.useState("");
  const [
    largeDescriptionReplaced,
    setLargeDescriptionReplaced,
  ] = React.useState("");
  const kids = useSelector((state) => state.kids.kids);
  const kidsDisabled = useSelector((state) => state.kids.kidsDisabled);
  const kidsRedux = useSelector((state) => state?.kids?.piggies);
  const tasks = useSelector((state) => state.tasks.tasks);
  const gifts = useSelector((state) => state.gifts.gifts.Gifts);
  const targets = useSelector((state) => state.targets.targets.Targets);
  const withdrawals = useSelector((state) => state.kids.withdrawals);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const findKid = (kidId) => {
      const allKids = [].concat(kids, kidsDisabled);
      const foundKid = allKids.find((kid) => kid.Id === +kidId);
      if (foundKid) {
        setRelatedKid(foundKid);
      }
    };
    switch (notification?.Category) {
      case "gift":
        const findGift =
          gifts && gifts.length > 0
            ? gifts.find((gift) => gift.Id === +notification?.RelatedData)
            : null;
        if (findGift && findGift.Id) {
          setRelatedInfo(findGift);
          findKid(findGift.ChildId);
        }
        break;
      case "task":
        const findTask = tasks.find(
          (task) => task.Id === +notification.RelatedData
        );
        if (findTask && findTask.Id) {
          setRelatedInfo(findTask);
          findKid(findTask.ChildId);
        }
        break;
      case "target":
        const findTarget = targets.find(
          (target) => target.Id === +notification.RelatedData
        );
        if (findTarget && findTarget.Id) {
          setRelatedInfo(findTarget);
          findKid(findTarget.ChildId);
        }
        break;
      default:
        setRelatedKid(null);
        setRelatedInfo(null);
    }
  }, [notification]);

  React.useEffect(() => {
    const replacedString =
      relatedKid && relatedKid.FirstName
        ? notification.LargeDescription.replace(
            "hijo",
            `hijo ${relatedKid?.FirstName} ${relatedKid?.LastName}`
          )
        : notification?.LargeDescription;
    setLargeDescriptionReplaced(replacedString);
  }, [relatedKid]);

  const renderIcon = (itemNo) => {
    switch (itemNo) {
      case "gift":
        return <img src="/savingsmenu.png" alt="Gift" />;
      case "target":
        return <img src="/targets.png" alt="Target" />;
      case "withdrawal":
        return <img src="/withdrawal.png" alt="Withdrawal" />;
      case "task":
        return <img src="/tasksmenu.png" alt="Task" />;
      default:
        return <img src='/kid_img_boy.png' alt='No image' />;
    }
  };

  const goTo = (value) => {
    markAsRead(notification?.Id);
    let timeOut = setTimeout(() => {
      switch (value) {
        case "gift":
          navigate("/dashboard/gifts");
          break;
        case "task":
          navigate("/dashboard/tasks");
          break;
        case "target":
          navigate("/dashboard/targets");
          break;
        case "withdrawal":
          const withdraw = withdrawals.find((w) => w.Id === Number(notification.RelatedData));
          if (withdraw && withdraw?.StatusCode !== "WITHDRAWALAPPROVED") {
            const kidFound = kidsRedux.find((item) => item.ChildId === withdraw.ChildId);
            const kid = { ...kidFound, withdraw };
            dispatch({
              type: types.SELECTED_KID,
              payload: kid,
            });
            navigate("/dashboard/pending-withdraw");
          } else {
            navigate("../");
          }
          break;
        case "default":
          navigate("/dashboard");
          break;
      }
      clearTimeout(timeOut);
    }, 500);
  };

  return (
    <div
      className={
        notification.IsRead
          ? `${styles.rowContainer} ${styles.fadeOutLeftAnimation}`
          : `${styles.rowContainer}`
      }
      onClick={() => goTo(notification.Category)}
    >
      <div className={styles.imageContainer}>
        {notification &&
          notification.Category &&
          renderIcon(notification?.Category)}
      </div>
      <div className={styles.notificationContainer}>
        <div className={styles.rowTitle}>
          <h2>
            {`${largeDescriptionReplaced}.
            ${
              notification.Category === "gift"
                ? relatedInfo && relatedInfo?.Motive
                : notification.Category === "task"
                ? relatedInfo && relatedInfo?.Description
                : notification.Category === "target"
                ? relatedInfo && relatedInfo?.Name
                : ""
            }`}
            {notification && (
              <span>
                {notification.Category === "gift"
                  ? relatedInfo &&
                    relatedInfo.Amount &&
                    ` $ ${relatedInfo?.Amount}`
                  : notification.Category === "task"
                  ? relatedInfo &&
                    relatedInfo.Amount &&
                    ` $ ${relatedInfo?.Amount}`
                  : notification.Category === "target"
                  ? relatedInfo && relatedInfo.Goal && ` $ ${relatedInfo?.Goal}`
                  : ""}
              </span>
            )}
          </h2>
        </div>
        <div className={styles.rowDate}>
          {moment(notification?.CreateDate).format("DD-MM-YYYY")}
          <span>
            {notification && notification.Category === "gift" && <h3></h3>}
          </span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RowNotifications);
