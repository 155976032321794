import React from "react";

import styles from "./loading.module.scss";

function Loading() {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading}>
        <img
          className={styles.chancha}
          src="/clinkky_sin_ocico.png"
          alt="Loading"
        />
        <img className={styles.chanchaHocico} src="/hocico.png" alt="Loading" />
      </div>
    </div>
  );
}

export default Loading;
