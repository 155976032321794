import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styles from './processing.module.scss';

const LinkProcessing = ({label, value, id}) => {
  const { t } = useTranslation();

  const copyClipboard = async () => {
    const text = document.getElementById(id).value;
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`${t('success.giftLinkCopied')}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log('Page URL copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className={styles.linkDataItem}>
      <div className={styles.linkContainer}>
        <p className={styles.linkLabel}>{`${t(label)}: `}</p>
        <div className={styles.link}>       
          <button onClick={copyClipboard} className={styles.linkText}>
            <input
              className={styles.linkInput}
              id={id}
              type='text'
              disabled
              defaultValue={value}
            />
          </button>
          <img
            className={styles.linkImage}
            src='/linkImageBlack.png'
            alt='Clinkky Link'
          />
        </div>
      </div>
    </div>
  );
};

export default LinkProcessing;
