import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Link } from "@reach/router";
import { useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";

import useRequest from "./../../hooks/useRequest.js";

import RowListRounded from "./../../Shared/RowListRounded/RowListRounded.jsx";
import KidAccountStatus from "././../Shared/KidAccountStatus.jsx";

import styles from "./kiddetail.module.scss";

function KidDetails({ children }) {
  const [moneySpent, setMoneySpent] = React.useState(0);
  const [moneyToReceive, setMoneyToReceive] = React.useState(0);
  const [moneyToReceiveFromThird, setMoneyToReceiveFromThird] = React.useState(
    0
  );
  const [moneyFromPersonal, setMoneyFromPersonal] = React.useState(0);
  const [moneyFromTasksDone, setMoneyFromTasksDone] = React.useState(0);
  const [moneyFromThird, setMoneyFromThird] = React.useState(0);
  const { t } = useTranslation();
  const [
    { data: data, loading: loading, error: error },
    executeRequest,
  ] = useAxios(
    {
      url: `${config.urlServer}/kids/transactions`,
      method: "POST",
    },
    { manual: true }
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const cookie = Cookies.get("user");
  const userRedux = useSelector((state) => state.auth.user);
  const kid = useSelector((state) => state.kids.selectedKid);
  const tasksDone = useSelector((state) => state.tasks.doneTasks);
  const kidTransactions = useSelector((state) =>
    kid && kid.Id ? state.kids.transactions[kid.Id] : []
  );
  const giftstoReceive = useSelector((state) => state.gifts.giftsAllDone);

  React.useEffect(() => {
    if (!cookie) {
    }
    if (
      userRedux &&
      userRedux.Id &&
      location.pathname === "/dashboard/details-kids"
    ) {
      getTransactions();
    }
  }, [location]);

  const { data: transactionsData, response: transactionsResponse } = useRequest(
    kid && kid.Child && kid.Child.Id
      ? {
          ...{
            url: `${config.urlServer}/kids/transactions`,
            method: "POST",
            data: { Id: kid?.Id, Cookie: cookie },
          },
          ...{
            initialData: { ParentId: userRedux?.Id, Cookie: cookie },
          },
        }
      : null
  );

  React.useEffect(() => {
    if (
      transactionsData &&
      transactionsData.OperationStatus === "Ok" &&
      transactionsData?.Childs?.length > 0
    ) {
      dispatch({
        type: types.FETCH_PIGGY_TRANSACTIONS_SUCCESS,
        payload: transactionsData?.Transactions?.reverse(),
      });
    }
  }, [transactionsData]);

  React.useEffect(() => {
    if (kidTransactions && kidTransactions.length > 0) {
      const moneyAlreadySpent = kidTransactions
        .map((trans) => {
          if (trans && trans.Type === "D") {
            return parseFloat(trans.Amount);
          }
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
      setMoneySpent(moneyAlreadySpent);
    }
  }, [kidTransactions]);

  React.useEffect(() => {
    if (giftstoReceive && giftstoReceive.length > 0) {
      const moneyToReceiveGift = giftstoReceive
        .map((gift) => {
          if (
            kid &&
            kid.ChildId === gift.ChildId &&
            gift &&
            gift.StatusCode === "GIFTAPPROVED"
          ) {
            return parseFloat(gift.Amount);
          }
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
      setMoneyToReceive(moneyToReceiveGift);
      const moneyToReceiveGiftFromThird = giftstoReceive
        .map((gift) => {
          if (
            kid &&
            kid.ChildId === gift.ChildId &&
            gift &&
            gift.StatusCode === "GIFTAPPROVED" &&
            !gift.IsInternal
          ) {
            return parseFloat(gift.Amount);
          }
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
      setMoneyToReceiveFromThird(moneyToReceiveGiftFromThird);
      const moneyFromPersonalReduce = giftstoReceive
        .map((gift) => {
          if (
            kid &&
            kid.ChildId === gift.ChildId &&
            gift &&
            gift.StatusCode === "GIFTRECEIVED" &&
            gift.IsInternal
          ) {
            return parseFloat(gift.Amount);
          }
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
      setMoneyFromPersonal(moneyFromPersonalReduce);
      const moneyFromThirdReduce = giftstoReceive
        .map((gift) => {
          if (
            kid &&
            kid.ChildId === gift.ChildId &&
            gift &&
            gift.StatusCode === "GIFTRECEIVED" &&
            !gift.IsInternal
          ) {
            return parseFloat(gift.Amount);
          }
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
      setMoneyFromThird(moneyFromThirdReduce);
      const moneyFromTasksDonedReduce = tasksDone
        .map((task) => {
          if (kid && task && kid.ChildId === task.ChildId) {
            return parseFloat(task.Amount);
          }
        })
        .filter(Boolean)
        .reduce((acc, curr) => {
          return (acc += curr);
        }, 0);
      setMoneyFromTasksDone(moneyFromTasksDonedReduce);
    }
  }, [giftstoReceive, kid, tasksDone]);

  const getTransactions = async () => {
    if (cookie && cookie.length > 0) {
      const response = await executeRequest({
        data: {
          Id: kid.Id,
          Cookie: cookie,
        },
      });
      if (response && response?.data && response?.data?.Transactions?.length > 0) {
        dispatch({
          type: types.FETCH_PIGGY_TRANSACTIONS_SUCCESS,
          payload: response?.data?.Transactions?.reverse(),
        });
      }
    }
  };

  return (
    <div className={styles.kidDetailContainer}>
      {children}
      <div className={styles.kidRow}>
        <img
          className={styles.kidPhoto}
          src={
            kid && kid.Child && kid.Child.Photo && kid.Child.Photo !== ' '
              ? kid?.Child?.Photo
              : '/kid_img_boy.png'
          }
          alt={kid?.Child?.FirstName}
        />
        <div className={styles.kidColumn}>
          <h1 className={styles.kidName}>
            {kid?.Child?.FirstName} {kid?.Child?.LastName}
          </h1>
          <div className={styles.actions}>
            <Link to='edit-kids'>
              <img
                src='/editGray.png'
                alt={`${t('kidDetail.edit')} ${kid?.FirstName}`}
              />
              {t('kidDetail.edit')}
            </Link>
            <Link to='disable-kids' id='disable-kids-btn'>
              <img
                src='/deleteGray.png'
                alt={`${t('kidDetail.disableKid')} ${kid?.Child?.FirstName}`}
              />
              {t('kidDetail.disableKid')}
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.aportesPersonales}>
        <div className={styles.aportesPersonalesContent}>
          <h2>{t('kidDetail.personalGift')}:</h2>
          <span>${moneyFromPersonal}</span>
        </div>
      </div>
      <div className={styles.aportesPersonales}>
        <div className={styles.aportesPersonalesContent}>
          <h2>{t('kidDetail.moneySpent')}:</h2>
          <span>${moneySpent}</span>
        </div>
      </div>
      <div className={styles.aportesPersonales}>
        <div className={styles.aportesPersonalesContent}>
          <h2>{t('kidDetail.moneyPending')}:</h2>
          <span>${moneyToReceive}</span>
        </div>
      </div>
      <div className={styles.aportesPersonales}>
        <div className={styles.aportesPersonalesContent}>
          <h2>{t('kidDetail.moneyFromTasks')}:</h2>
          <span>${moneyFromTasksDone}</span>
        </div>
      </div>
      <div className={styles.aportesPersonales}>
        <RowListRounded
          title={`${t('kidDetail.thirdGift')}:`}
          price={`$${moneyFromThird + moneyToReceiveFromThird}`}
        >
          <div>
            {t('kidDetail.currentMoney')}: <span>${moneyFromThird}</span>
          </div>
          <div>
            {t('kidDetail.pendingMoney')}:{' '}
            <span>${moneyToReceiveFromThird}</span>
          </div>
        </RowListRounded>
      </div>
      <div className={styles.aportesPersonales}>
        <div className={styles.total}>
          <h3>{t('kidDetail.total')}</h3>
          <span>${kid?.Balance}</span>
        </div>
      </div>
      {!loading && kidTransactions && <KidAccountStatus kid={kid} />}
    </div>
  );
}
export default React.memo(KidDetails);
