import * as React from "react";
import config from "../../../config/config";
import useAxios from "axios-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "@reach/router";
import { toast } from "react-toastify";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import KidForm from "../Shared/KidForm.jsx";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import useBase64 from "../../hooks/useBase64";

import styles from "./add.module.scss";

const Add = ({ navigate }) => {
  const [tempDeviceId, setTempDeviceId] = React.useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/kids/add-kid",
      method: "POST",
    },
    { manual: true }
  );
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  const getBase64 = useBase64();
  const userRedux = useSelector((state) => state.auth.user);
  const kidsRedux = useSelector((state) => state.kids.kids);
  const kidDeviceId = useSelector((state) => state.kids.kidDeviceId);

  React.useEffect(() => {
    if (!kidDeviceId && location.pathname === "/dashboard/new-kids") {
      setTempDeviceId(`temp-${userRedux?.Id}-${kidsRedux.length + 1}`);
    }
    return () => {};
  }, []);

  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  const onSubmit = async (user) => {
    const fileBase64 =
      user && user.Photo[0] ? await getBase64(user.Photo[0]) : null;
    const response = await executeRequest({
      data: {
        Person: {
          FirstName: user.FirstName,
          LastName: user.LastName,
          BirthDate: user.BirthDate,
          Gender: user.Gender.charAt(0),
          Photo: fileBase64,
          ParentId: userRedux.Id,
        },
        Device: {
          Value: !kidDeviceId ? "temp" : kidDeviceId,
        },
        Cookie: cookie,
      },
    });
    const kidDeviceCopy = kidDeviceId;
    dispatch({
      type: types.REGISTER_KID_SUCCESS,
      payload: response?.data?.Person?.Id,
    });
    if (kidDeviceCopy !== null) {
      navigate("../pairing-success");
    } else {
      navigate("../");
    }
  };

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("addKid.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="white"
        />
        <KidForm
          styles={styles}
          loading={loading}
          onSubmit={onSubmit}
          values={null}
        />
      </div>
    </div>
  );
};

export default Add;
