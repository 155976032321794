import React from "react";
import useAxios from "axios-hooks";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import types from "../../../reducers/types";
import Cookies from "js-cookie";

import config from "../../../config/config";
import Input from "./../../Shared/Input/Input.jsx";
import HeaderModal from "./../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./approve-tasks.module.scss";

export default function ApproveTask({ navigate }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url: config.urlServer + "/tasks/reject-task",
      method: "POST",
    },
    { manual: true }
  );
  const dispatch = useDispatch();
  const cookie = Cookies.get("user");
  const selectedTaskRedux = useSelector((state) => state.tasks.selectedTask);
  const { kid, task } = selectedTaskRedux;
  React.useEffect(() => {
    toast.configure({
      autoClose: 1000,
      draggable: false,
    });
    if (error && error !== null) {
      toast.error(`${error?.response?.data?.Error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);
  const onSubmit = async () => {
    const response = await executeRequest({
      data: {
        Id: task?.Id,
        Cookie: cookie,
      },
    });
    dispatch({
      type: types.STATUS_KIDS_SUCCESS,
      payload: response?.data,
    });
    navigate("/dashboard/tasks");
  };
  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          styles={styles}
          navigate={navigate}
          title={t('approveTasks.title')}
          backButton={false}
          closeButton={true}
          closeButtonColor='red'
        />
        <form className={styles.mainContent} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <img
              className={styles.pigPhoto}
              src={
                kid && kid?.Photo && kid?.Photo !== ' '
                  ? kid.Photo
                  : '/kid_img_boy.png'
              }
              alt={`${kid?.FirstName}`}
            />
          </div>
          <div className={styles.row}>
            <p>
              {kid.FirstName} {t('approveTasks.taskCompletedDate')} 02/05/2020
            </p>
          </div>
          <br />
          <div className={styles.row}>
            <p>{t('approveTasks.wannaReject')}</p>
          </div>
          <div className={styles.buttonContainer}>
            <button
              type='submit'
              className={[loading ? styles.btnLoginDisabled : styles.btnLogin]}
            >
              {t('approveTasks.reject')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
