import React from "react";
import { useTranslation } from "react-i18next";

import HeaderModal from "../../Shared/HeaderModal/HeaderModal.jsx";

import styles from "./verify-parent.module.scss";

export default function VerifyParent({ navigate }) {
  const { t } = useTranslation();

  React.useEffect(() => {}, []);

  return (
    <div className={styles.backDrop}>
      <div className={styles.container}>
        <HeaderModal
          navigate={navigate}
          title={t("verifyParent.title")}
          backButton={false}
          closeButton={true}
          closeButtonColor="white"
        />
        <p className={styles.clinkkyBankText}>
          {t("verifyParent.description")}
        </p>
        <img
          className={styles.clinkkyBank}
          src="/clinkky-banquero.png"
          alt="Clinkky banquero"
        />
        <br />
        <button
          onClick={() => navigate("/dashboard/profile")}
          className={styles.btnLogin}
        >
          {t("verifyParent.updateData")}
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
